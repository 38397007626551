import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {


  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 3,
          tabla: { cols: 3, rows: 3 },
          tablaBottom: { cols: 3, rows: 4 },
          miniCard: { cols: 3, rows: 10 }
        };
      }
 
     return {
        columns: 3,
        tabla: { cols: 3, rows: 3 },
        tablaBottom: { cols: 1, rows: 4 },
        miniCard: { cols: 1, rows: 7 }
      };
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
  }

}

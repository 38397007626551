<div class="grid-container">
  <h1 class="mat-h1">  <a class="breadcrumb-link contrast-primary" routerLink="/domains">Domains</a> > {{subdomain}}</h1>
  <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="{{( cardLayout | async )?.height}}">
    <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" 
                   [rowspan]="( cardLayout | async )?.tabla?.rows">
      <app-card title="Subdomains">

        <app-subdomain-tabla [subdomain]="subdomain" [primary]="primary"></app-subdomain-tabla>

        
      </app-card>
   </mat-grid-tile>

  </mat-grid-list>
</div>

<app-add-button [subdomain]="subdomain"></app-add-button>



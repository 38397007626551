<div>
  <table mat-table class="full-width-table" matSort matTableResponsive aria-label="Elements">

    <!-- Name Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let row"> 
        {{row.description}}
      </td>
    </ng-container>
    <ng-container matColumnDef="os">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>OS</th>
      <td mat-cell *matCellDef="let row"> 
        {{row.os}}
      </td>
    </ng-container>
    <ng-container matColumnDef="last_update">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last update</th>
      <td mat-cell *matCellDef="let row"> 
        {{row.last_update | date: 'yy-MM-dd, HH:mm:ss'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
      <td mat-cell *matCellDef="let row"> 
        {{row.source}}
      </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
      <td mat-cell *matCellDef="let row"> 
        {{row.value}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [class.display-none]="row.value==null && isMobile"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="8"
    [pageSizeOptions]="[8, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>
import { Component } from '@angular/core';
import { map } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 5,
          rowHeight: "830px",
          tabla: { cols: 5, rows: 1 },
          text: { cols: 5, rows: 1 }
        };
      }
      return {
        columns: 5,
        rowHeight: "95px",
        tabla: { cols: 3, rows: 8 },
        text: { cols: 2, rows: 8 }
      };
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) {
  }

}

import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, Subscription } from 'rxjs';
import { LoginService } from '../../Main/services/login.service';
import { UpdateService } from '../../Main/services/update.service';
import { SentinelThreatService } from 'src/app/Main/services/sentinel-threat.service';

// TODO: Replace this with your own data model type
export interface SentinelTablaItem {
  description:string;
  last_update: string;
  os: string;
  scope: string;
  scope_path: string;
  source: string;
  user: string;
  value: string;
}

// TODO: replace this with real data from your application
let EXAMPLE_DATA: SentinelTablaItem[] = [  
  {
    description: "Retrieving data...",
    last_update: "",
    os: "",
    scope: "",
    scope_path: "",
    source: "",
    user: "",
    value: ""
  }
];

/**
 * Data source for the Tabla view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SentinelTablaDataSource extends DataSource<SentinelTablaItem> {
  data: SentinelTablaItem[] = EXAMPLE_DATA;
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;
  subscription: Subscription;

  constructor(private sentinelThreatService: SentinelThreatService, private updateService: UpdateService
    ) {
    super();
    this.getSentinelData();
    this.subscription=this.updateService.getClickEventZones().subscribe(
      () => this.getSentinelData()
    )
  }

  getSentinelData(){
    this.sentinelThreatService.getThreat().subscribe(
      (success: any) => {
        let csv=success;

        let pars= SentinelTablaDataSource.csvToArray(csv)
        // this.sentinelThreats=pars;
        // console.log(pars)

        this.data=pars;
        this.paginator?._changePageSize(this.paginator?.pageSize); 
      }
    )
  }

  static csvToArray(str, delimiter = ",") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\r\n")).split(delimiter);
  
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1, -1).split("\r\n");

    const toSnakeCase = str =>
      str &&
      str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('_');
  
    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[toSnakeCase(header)] = values[index];
        return object;
      }, {});
      return el;
    });
    
  
    // return the array
    return arr;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<SentinelTablaItem[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(observableOf(this.data), this.paginator.page, this.sort.sortChange)
        .pipe(map(() => {
          return this.getPagedData(this.getSortedData([...this.data ]));
        }));
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: SentinelTablaItem[]): SentinelTablaItem[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;

      if( data.length -  startIndex < this.paginator.pageSize ){
        let diff = this.paginator.pageSize -(data.length - startIndex)
        for(let i=0 ; i<diff;i++){
          data.push(Object.create(null));
        }
      }
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: SentinelTablaItem[]): SentinelTablaItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'description': return compare(a.description, b.description, isAsc);
        case 'last_update': return compare(a.last_update, b.last_update, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        case 'OS': return compare(a.os, b.os, isAsc);
        case 'source': return compare(a.source, b.source, isAsc);

        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

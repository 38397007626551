import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "../../AppSettings";


@Injectable()
export class EditRecordService {


  private GetZonesUrlBase = AppSettings.API_ENDPOINT;



  constructor(private http: HttpClient) {
  }

  disableRecord(disable: boolean, record: string) {
    const url = this.GetZonesUrlBase + "disable-record";

    //let data = {"disable": disable }

    let params = new HttpParams()
      .set('record', record)
      .set('disable', disable);

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.put(
      url,
      "{}", //JSON.stringify( data ),
      httpOptions
    );

  }

  deleteRecord(record: string) {
    const url = this.GetZonesUrlBase + "record";


    let params = new HttpParams()
      .set('record', record);

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.delete(
      url,
      httpOptions
    );
  }

  editRecord(data: any, record: string) {
    const url = this.GetZonesUrlBase + "record";

    let params = new HttpParams()
      .set('record', record);

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.put(
      url,
      JSON.stringify(data),
      httpOptions
    );
  }

  parkingDomain(zone: string) {
    const url = this.GetZonesUrlBase + "parking-domain";

    let params = new HttpParams()
      .set('zone', zone)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.put(
      url,
      {},
      httpOptions
    );
  }


  disableZone(disable: boolean, zone: string) {
    const url = this.GetZonesUrlBase + "disable-zone";

    //let data = {"disable": disable }

    let params = new HttpParams()
      .set('zone', zone)
      .set('disable', disable);

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.put(
      url,
      "{}", //JSON.stringify( data ),
      httpOptions
    );

  }

  applyTemplate(zone: string) {
    const url = this.GetZonesUrlBase + "create-domain-template";

    //let data = {"disable": disable }

    let params = new HttpParams()
      .set('zone', zone)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.post(
      url,
      "{}", //JSON.stringify( data ),
      httpOptions
    );
  }

  getDnsData(zone: string, record: string, type: string) {
    const url = this.GetZonesUrlBase + "get-dns-data";

    let params = new HttpParams()
      .set('zone', zone)
      .set('record', record)
      .set('record_type', type)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.get(
      url,
      httpOptions
    );
  }

  editNSZone(data: any, zone: string) {
    const url = this.GetZonesUrlBase + "edit-zone";

    let params = new HttpParams()
      .set('zone', zone)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.put(
      url,
      JSON.stringify(data),
      httpOptions
    );

  }

}

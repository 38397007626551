import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "../../AppSettings";


@Injectable()
export class CreateRecordService {


    private GetZonesUrlBase = AppSettings.API_ENDPOINT;

    
    
    constructor(private http: HttpClient) {
    }

    createRecord(data:any, type:string){
        const url = this.GetZonesUrlBase + "record";


        let params = new HttpParams()
            .set('types', type);

        const httpOptions = {
            headers: new HttpHeaders(
              {
                'Content-Type': 'application/json'
              }
            ),
            params: params
          };
        
        return this.http.post(
            url,
            JSON.stringify( data ),
            httpOptions
        );

    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isUndefined } from 'util';

@Component({
  selector: 'app-change-grid-primary-dialog',
  templateUrl: './change-grid-primary-dialog.component.html',
  styleUrls: ['./change-grid-primary-dialog.component.css']
})
export class ChangeGridPrimaryDialogComponent implements OnInit {

  changeNsForm: FormGroup;
  isExternal: Boolean;

  constructor(
    public dialogRef: MatDialogRef<ChangeGridPrimaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    this.changeNsForm = this.formBuilder.group({
      name: [data[0]?.grid_primary[0]?.name],
      secondaryName: [data[0]?.grid_secondaries[0]?.name],
      secondaryName2: [data[0]?.grid_secondaries[1]?.name],
      externalName: [data[0]?.external_primaries[0]?.name],
      externalAddress: [data[0]?.external_primaries[0]?.address],
      externalName2: [data[0]?.external_primaries[1]?.name],
      externalAddress2: [data[0]?.external_primaries[1]?.address],
      externalSecondaryName1: [data[0]?.external_secondaries[0]?.name],
      externalSecondaryAddress1: [data[0]?.external_secondaries[0]?.address],
      externalSecondaryName2: [data[0]?.external_secondaries[1]?.name], 
      externalSecondaryAddress2: [data[0]?.external_secondaries[1]?.address],
      isExternal: [data[0]?.external_primaries[0]?.name !== undefined],
    });

    this.isExternal=data[0]?.external_primaries[0]?.name !== undefined
    //console.log(data[0]?.external_primaries[0]?.name !== undefined);
    //console.log(this.changeNsForm.value.isExternal.value[0])
  }

  ngOnInit(): void {
  }

  confirmChange() {

    console.log(this.changeNsForm.value.name)

    let response : any={
      "grid_primary": [],
      "external_primaries": [],
      "grid_secondaries": [],
      "external_secondaries": [],
      "use_external_primary": this.isExternal,
    }

    if(this.changeNsForm.value.name){
      response.grid_primary.push({ "name": this.changeNsForm.value.name });
    }
    if(this.changeNsForm.value.secondaryName){
      response.grid_secondaries.push( { "name": this.changeNsForm.value.secondaryName })
    }
    if(this.changeNsForm.value.secondaryName2){
      response.grid_secondaries.push( { "name": this.changeNsForm.value.secondaryName2 })
    }
    if(this.changeNsForm.value.externalName){
      response.external_primaries.push( { "name": this.changeNsForm.value.externalName, "address": this.changeNsForm.value.externalAddress})
    }
    if(this.changeNsForm.value.externalName2){
      response.external_primaries.push( { "name": this.changeNsForm.value.externalName2, "address": this.changeNsForm.value.externalAddress2})
    }
    if(this.changeNsForm.value.externalSecondaryName1){
      response.external_secondaries.push({ "name": this.changeNsForm.value.externalSecondaryName1, "address": this.changeNsForm.value.externalSecondaryAddress1 });
    }
    if(this.changeNsForm.value.externalSecondaryName2){
      response.external_secondaries.push({ "name": this.changeNsForm.value.externalSecondaryName2, "address": this.changeNsForm.value.externalSecondaryAddress2 });
    }

    console.log(response);



    let response2={
      "grid_primary": [{ "name": this.changeNsForm.value.name }],
      "external_primaries": [
        //{ "name": (this.changeNsForm.value.externalName || "" ), "address": (this.changeNsForm.value.externalAddress || "")},
        //{ "name": (this.changeNsForm.value.externalName2 || "") , "address": (this.changeNsForm.value.externalAddress2 || "")}
      ],
      "grid_secondaries": [
        { "name": this.changeNsForm.value.secondaryName  },
        /*{ "name": (this.changeNsForm.value.secondaryName2 || null )}*/
      ],
      "external_secondaries": [
        //{ "name": (this.changeNsForm.value.externalSecondaryName1 || "" )},
        //{ "name": (this.changeNsForm.value.externalSecondaryName2 || "" )}
      ],
    }

    console.log(response2);


    this.dialogRef.close([
      'confirmChange',
      this.data[0]._ref,
      response
    ]);
  }

  closeDialog() {
    this.dialogRef.close(['close', null]);
  }

  changePrimary(){
    //this.isExternal=!this.isExternal
    this.changeNsForm.patchValue({
      name: "",
      externalName: "",
      externalAddress: "",
      externalName2: "",
      externalAddress2: "",
    }

    )
    //console.log(this.changeNsForm.value.name)
  }
}

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "../../AppSettings";


@Injectable()
export class ConfigurationService {


    private GetConfigurationBase = AppSettings.API_ENDPOINT;



    constructor(private http: HttpClient) {
    }


    getConfigData() {
        const url = this.GetConfigurationBase + "config-data";
    
        /*let params = new HttpParams()
          .set('zone', zone)
          .set('record', record)
          .set('record_type', type)*/
    
        const httpOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json'
            }
          ),
        };
    
        return this.http.get(
          url,
          httpOptions
        );
    }

    editChanges(data){
        const url = this.GetConfigurationBase + "config-data";
        Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);

        /*let params = new HttpParams()
          .set('zone', zone)
          .set('record', record)
          .set('record_type', type)*/
    
        const httpOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json'
            }
          ),
        };
    
        return this.http.put(
          url,
          JSON.stringify(data),
          httpOptions
        );
    }
}
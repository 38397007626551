import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { concatMap, elementAt, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { Observable, of as observableOf, merge, concat, from, forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UpdateService } from 'src/app/Main/services/update.service';
import { LoginService } from 'src/app/Main/services/login.service';
import { Component, OnDestroy } from '@angular/core';

// TODO: Replace this with your own data model type
export interface SubdomainTablaItem {
  name: string;
  comment: string;
  type: string;
  address: string;
  disable: boolean;
  view: string;
  zone: string;
  _ref: string;
}

// TODO: replace this with real data from your application
let EXAMPLE_DATA: SubdomainTablaItem[] = [
  { name: "Retrieving subdomains...", comment: "", type: "", address: "", disable: false, view: "", zone: "", _ref: "" }
];

/**
 * Data source for the Tabla view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SubdomainTablaDataSource extends DataSource<SubdomainTablaItem> {
  data: SubdomainTablaItem[] = EXAMPLE_DATA;
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  subscription: Subscription


  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private updateService: UpdateService
  ) {
    super();
    this.getSubdomain();
    this.subscription = this.updateService.getClickEvent().subscribe(
      () => this.getSubdomain()
    )
  }

  getSubdomain() {
    const subdomain = this.route.snapshot.paramMap.get('id')?.split("/")[0] || "";
    const primary = this.route.snapshot.paramMap.get('primary') == "true" || false;

    this.loginService.getSubdomains(subdomain).subscribe(
      (success: any) => {
        //let modded_array=success.map(function(element:any){
        //  return {name: element._ref}
        //})
        let modded_array = success.map(function (element: any) {
          return {
            ...element,
            parsedType: element.record?._ref.split("/")[0]
          }
        })

        this.data = modded_array;
        //this.data=success

        this.paginator?._changePageSize(this.paginator?.pageSize);

        if (!primary) {
          concat(
            from(this.data).pipe(
              filter((record: any) => record.name != ''),
              filter((record: any) => record.type != 'UNSUPPORTED'),
              mergeMap((record: any, index) =>
                this.loginService.getDnsData(record.zone, record.name, record.type)
                  .pipe(map(
                    (success: any) => {
                      record['record'] = success
                      return record

                    }
                  ))
              )
            )
          )
            .subscribe(
              success => { },
              error => { },
              () => {
                //this.data=success
                this.paginator?._changePageSize(this.paginator?.pageSize);
              }
            )
        }
      }
    );
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<SubdomainTablaItem[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(observableOf(this.data), this.paginator.page, this.sort.sortChange)
        .pipe(map(() => {
          return this.getPagedData(this.getSortedData([...this.data]));
        }));
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: SubdomainTablaItem[]): SubdomainTablaItem[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;

      if (data.length - startIndex < this.paginator.pageSize) {
        let diff = this.paginator.pageSize - (data.length - startIndex)
        for (let i = 0; i < diff; i++) {
          data.push(Object.create(null));
        }
      }
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: SubdomainTablaItem[]): SubdomainTablaItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'comment': return compare(a.comment, b.comment, isAsc);
        case 'type': return compare(a.type, b.type, isAsc);
        case 'record': return compare(a.type, b.type, isAsc);
        case 'disable': return compare(a.disable ? "true" : "false", b.disable ? "true" : "false", isAsc);

        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

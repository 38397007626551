
<div class="grid-container">
    <h1 class="mat-h1">Conflicts</h1>

    <div class="header-buttons">
      <button mat-raised-button (click)="sendAnalysis($event)" color="accent">Analyse</button>
      <button mat-raised-button (click)="sendClean($event)" color="accent">Clean</button>
      <!-- <button mat-raised-button (click)="test()" color="accent">Testing</button> -->


      <div class="spacer"></div>
      <button mat-raised-button (click)="download()" color="accent">Download log</button>
    </div>

    <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="95px">
      <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
        <app-card>

          <div id="terminal">
            <ul id='messages'>
              <li>{{myDate | date: 'yyyyMMddHHmm' }}</li>
              <li>Establishing connection<span id="blink">...</span></li>
            </ul>
          </div>
        </app-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.text?.cols" [rowspan]="( cardLayout | async )?.text?.rows">
        <app-card title="What can I do?" >
          <ul>
            <li>
              When assigning ranges to be distributed automatically by DHCP, it is possible that conflicts may arise with fixed IPs, previously identified by hand in the network, and that they fall within one of those ranges.</li>
            <li>“Holes” are created in the DHCP ranges, with the fixed IPs that were previously assigned and become Fixed Address, thus preventing them from being assigned twice and conflicts.</li>
            <li>We can press the <b>Analyse</b> button, to preexecute the algorith to check what would be the result of the cleaning</li>
            <li>After checking the result we can execute the cleaning with the button <b>Clean</b></li>
          </ul>
        </app-card>
      </mat-grid-tile>
    </mat-grid-list>
</div>



    

import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class UpdateService {


  private updateTable = new Subject<any>();
  private updateTableZones = new Subject<any>();
  private badgeNumber = new Subject<any>();



  sendUpdateEvent() {
    this.updateTable.next(null);
  }
  getClickEvent(): Observable<any>{ 
    return this.updateTable.asObservable();
  }

  sendUpdateEventZones() {
    this.updateTableZones.next(null);
  }
  getClickEventZones(): Observable<any>{ 
    return this.updateTableZones.asObservable();
  }

  sendBadgeUpdate(number: any) {
    this.badgeNumber.next(number);
  }
  getBadgeUpdate(): Observable<any>{ 
    return this.badgeNumber.asObservable();
  }

}
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-record',
  templateUrl: './create-record.component.html',
  styleUrls: ['./create-record.component.css']
})
export class CreateRecordComponent implements OnInit {

  newRecordForm: FormGroup;

  editMode= false;
  editText= "Add";

  ipv4Pattern='^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
  ipv6Pattern="^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$"
  fqdnPattern='^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$'

  constructor(
    public dialogRef: MatDialogRef<CreateRecordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    ) {


      this.newRecordForm=this.formBuilder.group({
        name: [""],
        ipv4addr: ["", [Validators.required, Validators.pattern(this.ipv4Pattern)]],
        ipv6addr: ["",[Validators.required, Validators.pattern(this.ipv6Pattern)]],
        canonical: ["",[Validators.required]],
        mail_exchanger: ["", [Validators.required , Validators.pattern(this.fqdnPattern)]],
        preference: ["",[Validators.required , Validators.min(1), Validators.max(100)]],
        nameserver: ["",[Validators.required]],
        addresses: ["",[Validators.required]],
        ptrdname: ["",[Validators.required]],
        text: ["",[Validators.required]],
        port: ["", [Validators.required, Validators.min(1), Validators.max(65535)]],
        priority: ["", [Validators.required, Validators.min(1), Validators.max(50)]],
        target: ["",[Validators.required]],
        weight: ["",[Validators.required]]
      })
      this.newRecordForm.controls['ipv4addr'].disable();
      this.newRecordForm.controls['ipv6addr'].disable();
      this.newRecordForm.controls['canonical'].disable();
      this.newRecordForm.controls['mail_exchanger'].disable();
      this.newRecordForm.controls['preference'].disable();
      this.newRecordForm.controls['nameserver'].disable();
      this.newRecordForm.controls['addresses'].disable();
      this.newRecordForm.controls['ptrdname'].disable();
      this.newRecordForm.controls['text'].disable();
      this.newRecordForm.controls['port'].disable();
      this.newRecordForm.controls['priority'].disable();
      this.newRecordForm.controls['target'].disable();
      this.newRecordForm.controls['weight'].disable();
      if(this.data[0]=="A"){
        this.newRecordForm.controls['ipv4addr'].enable();
      } else if(this.data[0]=="AAAA"){
        this.newRecordForm.controls['ipv6addr'].enable();
      } else if(this.data[0]=="CNAME"){
        this.newRecordForm.controls['canonical'].enable();
      } else if(this.data[0]=="MX"){
        this.newRecordForm.controls['mail_exchanger'].enable();
        this.newRecordForm.controls['preference'].enable();
      } else if(this.data[0]=="NS"){
        this.newRecordForm.controls['nameserver'].enable();
        this.newRecordForm.controls['name'].disable();
        //this.newRecordForm.controls['addresses'].enable();
      } else if(this.data[0]=="PTR"){
        this.newRecordForm.controls['ipv4addr'].enable();
        this.newRecordForm.controls['ptrdname'].enable();
      } else if(this.data[0]=="TXT"){
        this.newRecordForm.controls['text'].enable();
      } else if(this.data[0]=="SRV"){
        this.newRecordForm.controls['port'].enable();
        this.newRecordForm.controls['priority'].enable();
        this.newRecordForm.controls['target'].enable();
        this.newRecordForm.controls['weight'].enable();
      }

      this.editMode=this.data[2];

      if(this.editMode){
        this.editText= "Edit";

        this.newRecordForm.patchValue(
          this.data[3].record
        );
        if(this.data[0]=="PTR"){
          this.newRecordForm.controls['ptrdname'].setValue(this.newRecordForm.value.ptrdname.replaceAll("."+this.data[1], ''));
          this.newRecordForm.controls['name'].setValue(this.newRecordForm.value.name.replaceAll(".in-addr.arpa", ''));
        }
        else {
          if(this.newRecordForm.controls['name'].value==this.data[1]){
            this.newRecordForm.controls['name'].setValue("")
          }
          else{
            this.newRecordForm.controls['name'].setValue(this.newRecordForm.value.name.replaceAll("."+this.data[1], ''));
          }
        }

      }
     }

  ngOnInit(): void {
  }

  createRecord(){
    if(!this.editMode){
      if(this.data[0]=="PTR"){
        this.newRecordForm.value.ptrdname=this.newRecordForm.value.ptrdname+"."+this.data[1];
        this.newRecordForm.value.name=this.newRecordForm.value.name+".in-addr.arpa";
      }
      else {
        this.newRecordForm.value.name=this.newRecordForm.value.name+"."+this.data[1];
      }
      this.dialogRef.close(['addRecord', [this.data[0], this.newRecordForm.value]]);
    }
    else{
      if(this.data[0]=="PTR"){
        this.newRecordForm.value.ptrdname=this.newRecordForm.value.ptrdname+"."+this.data[1];
        this.newRecordForm.value.name=this.newRecordForm.value.name+".in-addr.arpa";
      }
      else if (this.data[0]=="NS"){

      }
      else {
        this.newRecordForm.value.name=this.newRecordForm.value.name+"."+this.data[1];
      }
      this.dialogRef.close(['editRecord', [this.data[0], this.newRecordForm.value]]);
    }
  }

  closeDialog(){
    this.dialogRef.close(['close', null]);
  }
}

<mat-progress-bar class="progress-bar-top" mode="indeterminate" color="accent" [class.visible]="searching"></mat-progress-bar>

<mat-toolbar (click)="$event.stopPropagation()" class="search-block mat-elevation-z4" [class.active]="toggleSearch">
    <mat-toolbar-row style="padding: 0 5px;">
        <button class="search-icon" mat-icon-button disabled>
    <mat-icon color="primary">search</mat-icon>
  </button>
  <input class="search-control color-primary" type="text" placeholder="Search" 
  (keydown.enter)="sendSearch()" [(ngModel)]="searchText" #searchbar>
  <button [disabled]="searchText.length==0" mat-button mat-icon-button color="accent" (click)="sendSearch()">
    <mat-icon>send</mat-icon>
  </button> 
  <button mat-button mat-icon-button color="warn" (click)="searchClose()">
    <mat-icon>close</mat-icon>
  </button> 
</mat-toolbar-row>
</mat-toolbar>


<app-search-result [searchResults]="searchResults"></app-search-result>
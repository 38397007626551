import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { BackupService } from 'src/app/Main/services/backup.service';
import { ConfigurationService } from 'src/app/Main/services/configuration.service';
import { MessageSnackBarService } from 'src/app/Main/services/snack-bar.service';
import { LoginService } from 'src/app/Main/services/login.service';

import * as Forge from 'node-forge';

@Component({
  selector: 'app-sentinel-config',
  templateUrl: './sentinel-config.component.html',
  styleUrls: ['./sentinel-config.component.css']
})
export class SentinelConfigComponent implements OnInit {

  sentinelForm: FormGroup;

  publicKey="";

  preChangePass="";

  ipv4Pattern='^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
  networkPattern='(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-2]{0,1}[0-9]{0,1}'
  networkListPattern='^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-2]{0,1}[0-9]{0,1})(\,(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-2]{0,1}[0-9]{0,1})*$'


  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private messageSnackBarService: MessageSnackBarService,
    private loginService: LoginService,
    ) {

    this.getPublicKey();

    this.sentinelForm=this.formBuilder.group({
      sentinelIp: [""],
      sentinelAPIKey: [""]
    })
   }

  ngOnInit(): void {
    this.configurationService.getConfigData().subscribe(
      success=>{
        this.sentinelForm.controls['sentinelIp'].setValue(success['sentinelIp'])
        this.sentinelForm.controls['sentinelAPIKey'].setValue(success['sentinelAPIKey'])
        this.preChangePass=success['sentinelAPIKey']

      
      }
    )

  }

  confirmChanges(){

    if(this.preChangePass==this.sentinelForm.controls['sentinelAPIKey'].value){
  
      let data=this.sentinelForm.value
      data['sentinelAPIKey']="";
      Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);

      this.configurationService.editChanges(data).subscribe(
        success=>{
          this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);

        }
      );
    }
    else{
      let codedApiKey=this.codePass(this.sentinelForm.controls['sentinelAPIKey'].value)
      this.sentinelForm.controls['sentinelAPIKey'].setValue(codedApiKey)
  
      this.configurationService.editChanges(this.sentinelForm.value).subscribe(
        success=>{
          this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);

        }
      );
    }


  }

  // confirmIncludeChanges(){
  //   // console.log(this.excludeCleanForm.value)
  //   // console.log(JSON.stringify(this.excludeCleanForm.value))



  //   this.configurationService.editChanges(this.sentinelForm.value).subscribe(
  //     success=>{
  //       console.log(success)
  //     }
  //   );

  // }

  getPublicKey(){
    this.loginService.getPublicKey().subscribe(
      (data) => {
        this.publicKey = data.toString();
      })
  }


  codePass(pass){

    const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    const pass_rsa=Forge.util.encode64(rsa.encrypt(pass.toString(), 'RSA-OAEP'));

    return pass_rsa

  }
}


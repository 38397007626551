<div class="grid-container">
  <h1 class="mat-h1">Domains</h1>
  <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="{{( cardLayout | async )?.rowHeight}}">
    <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
      <app-card title="Current domains">
        <app-tabla></app-tabla>
      </app-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="( cardLayout | async )?.text?.cols" [rowspan]="( cardLayout | async )?.text?.rows">
      <app-card title="What can I do?" >
        <app-stepper-info></app-stepper-info>
      </app-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
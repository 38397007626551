import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class GarbageService {

  private garbageBase = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient) { }


  cleanGarbage() {
    const url = this.garbageBase + "clean-garbage";
  
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
    };

    return this.http.post(
      url,
      {},
      httpOptions
    );
  }

  createSchedule(schedule:string){
    const url = this.garbageBase + "clean-schedule";
  
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
    };

    return this.http.post(
      url,
      JSON.stringify({"schedule": schedule}),
      httpOptions
    );
  }
}

import {Injectable} from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

/**
 * Service to create Snack messages
 */
@Injectable()
export class MessageSnackBarService {

  /**
   * Service constructor
   * @param snackBar
   * @param translate
   */
  constructor(private snackBar: MatSnackBar) {

  }


  /**
   * Creates a custom message
   * @param text
   * @param time
   */
  createDirectMessage(text:string, time:number) {
    this.snackBar.open(text, "", {
      duration: time,
      panelClass: ['line-break-snackbar']
    } as MatSnackBarConfig);
  }
}

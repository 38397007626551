import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/AppSettings';

@Injectable()
export class SentinelThreatService {

  private ThreatUrlBase = AppSettings.API_ENDPOINT;


  constructor(private http: HttpClient) { }


  getThreat(){
    const url = this.ThreatUrlBase + "sentinel-threat";

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'text/csv'
        }
      ),
    };

    return this.http.get(
      url,
      httpOptions
    );
}
}


<div class="program-cleaning">

    <mat-radio-group
    aria-labelledby="week-radio-group-label"
    class="week-radio-group"
    [(ngModel)]="cleaningDay">
    <mat-radio-button class="week-radio-button" *ngFor="let day of week" [value]="day">
        {{day}}
    </mat-radio-button>
    </mat-radio-group>


    <!-- <input [ngxMatTimepicker]="picker">   -->

    <mat-form-field>
        <input matInput
            name="selected_time_B"
            [format]="24"
            [(ngModel)]="selectedTime"
            placeholder="Time of execution"
            [ngxMatTimepicker]="picker"
            readonly />
        <mat-icon matSuffix
                (click)="picker.open()">
            watch_later
        </mat-icon>
    </mat-form-field>
    <ngx-mat-timepicker #picker></ngx-mat-timepicker>  




</div>

<div class="horizontal-spacing">
<button mat-raised-button (click)="cleanGarbage($event)" color="primary">Manual cleaning</button>
<button mat-raised-button (click)="saveConfig($event)" color="accent">Save schedule</button>
</div>
<!--card.component.html-->
<mat-card class="dashboard-card no-elevate" *ngIf="!closed">
    <mat-card-header>
        <mat-card-title>
            {{title}}
            <button *ngIf="closable" mat-icon-button class="more-button"  aria-label="remove card" (click)="closeCard()">
                <mat-icon>close</mat-icon>
            </button>

        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="dashboard-card-content">
        <ng-content></ng-content>
    </mat-card-content>

    <mat-card-actions>
        <ng-content select="[actions]"></ng-content>
    </mat-card-actions>
 </mat-card>
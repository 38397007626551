import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { SentinelThreatService } from 'src/app/Main/services/sentinel-threat.service';

@Component({
  selector: 'app-threat-intel',
  templateUrl: './threat-intel.component.html',
  styleUrls: ['./threat-intel.component.css']
})
export class ThreatIntelComponent implements OnInit {

  sentinelThreats={};
  showProgress=false;
  showDoubleProgress=false;

  systems= [
    {value: 'sentinel', viewValue: 'Sentinel'},
    {value: 'blox', viewValue: 'Blox One'}
  ];

  selected= 'sentinel'

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 5,
          tabla: { cols: 5, rows: 5 },
          mid: { cols: 5, rows: 4 },
          form: { cols: 5, rows: 3 },
          text: { cols: 5, rows: 5 }
        };
      }
 
     return {
        columns: 5,
        tabla: { cols: 5, rows: 7 },
        mid: { cols: 5, rows: 2 },
        form: { cols: 1, rows: 2 },
        text: { cols: 1, rows: 8 }
      };
    })
  );

  constructor(
      private breakpointObserver: BreakpointObserver,
      private sentinelThreatService:SentinelThreatService
) { }

  ngOnInit(): void {
    // this.getSentinelData();
  }
  


  getSentinelData(){
    this.sentinelThreatService.getThreat().subscribe(
      (success: any) => {
        // console.log(success);
        let csv=success;

        let pars= ThreatIntelComponent.csvToArray(csv)
        this.sentinelThreats=pars;
        console.log(pars)
      }
    )
  }

  static csvToArray(str, delimiter = ",") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\r\n")).split(delimiter);
  
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\r\n");
  
    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });
  
    // return the array
    return arr;
  }

}

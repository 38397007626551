<div class="grid-container">
    <h1 class="mat-h1">Configuration</h1>
    <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="95px">
        <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
            <app-card title="Current domains">
                <app-tabla></app-tabla>
            </app-card>
        </mat-grid-tile>


    </mat-grid-list>


</div>
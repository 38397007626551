

<div class="grid-container">
    <h1 class="mat-h1">Configuration</h1>
    <form class="example-form" [formGroup]="excludeCleanForm">

      <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="110px">
          <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
            <app-card title="Conflict configuration">
                  <p>
                      <mat-form-field appearance="fill">
                      <mat-label>Includes</mat-label>
                      <input matInput formControlName="include">
                      </mat-form-field>
                  </p>
                  <p>
                      <mat-form-field appearance="fill">
                      <mat-label>Excludes</mat-label>
                      <input matInput formControlName="exclude">
                      </mat-form-field>
                  </p>

                  <div>
                    <button mat-raised-button color="accent" mat-button (click)="confirmIncludeChanges()" 
                    [disabled]="!excludeCleanForm.valid">Confirm</button>
                  </div>


            </app-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
              <app-card title="Sentinel Configuration">
                <app-sentinel-config></app-sentinel-config>

                <!-- <p>
                  <mat-form-field appearance="fill">
                  <mat-label>Sentinel Ip</mat-label>
                  <input matInput formControlName="sentinelIp">
                  </mat-form-field>
                </p>
    
                <p>
                  <mat-form-field appearance="fill">
                  <mat-label>Sentinel API Key</mat-label>
                  <input type="password" matInput formControlName="sentinelAPIKey">
                  </mat-form-field>
                </p> -->
              </app-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
            <app-card  title="Backup configuration">


  
              <mat-selection-list class="back-list" #backups [multiple]="false">
                <div mat-subheader>Backup</div>
                <mat-list-option *ngFor="let note of backupList"  [value]="note.name">
                  <mat-icon mat-list-icon>note</mat-icon>
                  <div mat-line>{{note.name}}</div>
                  <div mat-line>{{note.date}}</div>
                </mat-list-option>
              </mat-selection-list>

              <div actions>
                <p>
                  Selected file to download: {{backups.selectedOptions.selected[0]?.value}}
                </p>
                <p class="horizontal-spacing">
                  <button mat-raised-button color="primary" mat-button (click)="createBackup()">Create backup now</button>
                  <button mat-raised-button color="accent" mat-button (click)="downloadBackup()">Download backup</button>
                </p>
              </div>
            </app-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="( cardLayout | async )?.tablaBottom?.cols" [rowspan]="( cardLayout | async )?.tablaBottom?.rows">
            <app-card title="Empty recycle bin">

              <app-garbage></app-garbage>
  
            </app-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="( cardLayout | async )?.tablaBottom?.cols" [rowspan]="( cardLayout | async )?.tablaBottom?.rows">
            <app-card>
  
            </app-card>
          </mat-grid-tile>
      </mat-grid-list>

    </form>

</div>
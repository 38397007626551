import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UpdateService } from 'src/app/Main/services/update.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  notificationList=[]
  
  badgeNumber=this.notificationList.length;


  constructor(private updateService:UpdateService) {

    this.getListNotifications()
  }

  ngOnInit(): void {
  }

  getListNotifications(){
    this.updateService.sendBadgeUpdate(this.badgeNumber)
  }
}

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { throws } from 'assert';
import { map, Observable } from 'rxjs';
import { AppSettings } from 'src/app/AppSettings';
import { AuthInterceptor } from 'src/app/Main/extraModules/auth.interceptor';

@Component({
  selector: 'app-conflicts',
  templateUrl: './conflicts.component.html',
  styleUrls: ['./conflicts.component.css']
})
export class ConflictsComponent implements OnInit {

  ws;
  valueInput;

  blockPage=false;
  
  
  myDate = new Date();




  /*@HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    
    return !this.blockPage;
  }*/

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 5,
          tabla: { cols: 5, rows: 5 },
          miniCard: { cols: 5, rows: 1 },
          form: { cols: 5, rows: 3 },
          text: { cols: 5, rows: 5 }
        };
      }
 
     return {
        columns: 5,
        tabla: { cols: 4, rows: 8 },
        miniCard: { cols: 1, rows: 1 },
        form: { cols: 1, rows: 2 },
        text: { cols: 1, rows: 8 }
      };
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) {


    const user = localStorage.getItem("userName");
    const pass = sessionStorage.getItem("userPassword");

    const wsURl=AppSettings.API_ENDPOINT.replace("http", "ws")
    this.ws = new WebSocket(wsURl + "ws");
    // console.log(AppSettings.API_ENDPOINT.replace("http", "ws"))
    this.ws.onopen = function(event) {
      this.send('Basic ' + btoa(user + ":" + pass))
    }
    AuthInterceptor.setLimitHour();

    this.ws.onmessage = function(event) {
        var messages = document.getElementById('messages')
        var message = document.createElement('li')
        var content = document.createTextNode(event.data)

        message.appendChild(content)
        //messages?.appendChild(message)
        messages?.insertAdjacentElement('beforeend', message)

        message.scrollIntoView({behavior: "auto"});
    };

    /*window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });*/
  }

  test(){
    var messages = document.getElementById('messages')
    var message = document.createElement('li')
    var content = document.createTextNode("Establishing connection...")

    message.appendChild(content)
        // messages?.appendChild(message)
    messages?.insertAdjacentElement('beforeend', message)

    message.scrollIntoView({behavior: "auto"});
  }

   

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ws.close();
  }

  
  // sendMessage(event) {
  //     var input = document.getElementById("messageText")

  //     this.ws.send(this.valueInput)
  //     this.valueInput = ''
  //     event.preventDefault()
  // }

  sendAnalysis(event){
    document.getElementById('messages')?.replaceChildren()
    this.blockPage=true;

    this.ws.send("start");

  }


  sendClean(event){
    document.getElementById('messages')?.replaceChildren()
    this.blockPage=true;

    this.ws.send("clean");

  }

  download(){

    let list= document.getElementsByTagName("li");

    let list2: Array<string>=[];
    for (var i = 0; i < list.length; i++) {
      list2.push(list[i].innerText+"\r\n");
    }

    const blob = new Blob(list2, { type: 'text/csv' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }
}

<form class="example-form" [formGroup]="parkingForm">

    <h1 mat-dialog-title>{{data[0]}} parking</h1>
    <div mat-dialog-content>
      <p>
      <mat-form-field appearance="fill">
        <mat-label>Ipv4addr</mat-label>
        <input matInput formControlName="ipv4addr">
      </mat-form-field>
      </p>
    </div>

    <div mat-dialog-actions>
        <button mat-button (click)="closeDialog()">No</button>
        <button mat-button (click)="parking()" [disabled]="!parkingForm.valid" cdkFocusInitial>Yes</button>
      </div>
</form>
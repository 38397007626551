import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../../AppSettings";

@Injectable()
export class BackupService {

  private BackupBase = AppSettings.API_ENDPOINT;


  constructor(private http: HttpClient) {
  }


  createBackup() {
      const url = this.BackupBase + "create-backup";
    
      const httpOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json'
          }
        ),
      };
  
      return this.http.get(
        url,
        httpOptions
      );
  }

  getList(){
    const url = this.BackupBase + "get-backup-list";

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
    };

    return this.http.get(
      url,
      httpOptions
    );
  }

  downloadBackup(filename){
    const url = this.BackupBase + "dowload-backup";


    let params = new HttpParams()
          .set('backup', filename)

    const headers= new HttpHeaders(
      {
        'Content-Type': 'application/octet-stream',
        'accept': 'application/json',
      }
    );

    const httpOptions = {
      headers: headers,
      params: params,
      responseType: 'blob' as 'json',
    };

    return this.http.get<any>(
      url,
      httpOptions
    );
  }



}

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "../../AppSettings";

import * as Forge from 'node-forge';


@Injectable()
export class LoginService {


  private GetZonesUrlBase = AppSettings.API_ENDPOINT;



  constructor(private http: HttpClient) {
  }

  getPublicKey(){
    const url_key = this.GetZonesUrlBase + "return-key";

    return this.http.get(url_key)
  }

  checkLogin(username: string, password: string, publicKey:string) {
    const url = this.GetZonesUrlBase + "check-login";
    
    const rsa = Forge.pki.publicKeyFromPem(publicKey);
    const pass_rsa=Forge.util.encode64(rsa.encrypt(password.toString(), 'RSA-OAEP'));

    localStorage.setItem("userName", username)
    sessionStorage.setItem("userPassword", pass_rsa)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ":" + pass_rsa)
        }
      ),
    };

    return this.http.get(
      url,
      httpOptions
    );

  }

  getZones() {
    const url = this.GetZonesUrlBase + "get-zones";

    let params = new HttpParams()

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.get(
      url,
      httpOptions
    );
  }

  getSubdomains(zone: any) {
    const url = this.GetZonesUrlBase + "record";

    let params = new HttpParams()
      .set('zone', zone);



    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.get(
      url,
      httpOptions
    );

  }


  getDnsData(zone: string, record: string, type: string) {
    const url = this.GetZonesUrlBase + "get-dns-data";

    let params = new HttpParams()
      .set('zone', zone)
      .set('record', record)
      .set('record_type', type)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      params: params
    };

    return this.http.get(
      url,
      httpOptions
    );
  }
}

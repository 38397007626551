
<form class="example-form" [formGroup]="sentinelForm">

    <p>
        <mat-form-field appearance="fill">
        <mat-label>Sentinel Ip</mat-label>
        <input matInput formControlName="sentinelIp">
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill">
        <mat-label>Sentinel API Key</mat-label>
        <input type="password" matInput formControlName="sentinelAPIKey">
        </mat-form-field>
    </p>

    <button mat-raised-button color="accent" mat-button (click)="confirmChanges()" 
    [disabled]="!sentinelForm.valid">Confirm</button>

</form>
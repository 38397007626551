import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';

@Injectable()
export class ConfirmationGuard implements CanDeactivate<any> {

  constructor() {}

  canDeactivate(component: any): boolean {
    if (component.ifAllowed) {
      return confirm('Are you sure?');
    }
    return true;
  }
}
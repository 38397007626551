import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './Pages/login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FormularioComponent } from './ExampleParts/formulario/formulario.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TablaComponent } from './Parts/tabla/tabla.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { ArbolComponent } from './ExampleParts/arbol/arbol.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthGuard } from './Main/extraModules/auth.guard';
import { CardComponent } from './Parts/card/card.component';
import { LoginService } from './Main/services/login.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SubdomainsComponent } from './Pages/subdomains/subdomains.component';
import { SubdomainTablaComponent } from './Parts/subdomain-tabla/subdomains-tabla.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddButtonComponent } from './Parts/add-button/add-button.component';
import { CreateRecordComponent } from './Parts/create-record/create-record.component';
import { MatTooltipDefaultOptions, MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { ExampleDashboardComponent } from './ExampleParts/example-dashboard/example-dashboard.component';
import { ErrorPageComponent } from './Pages/error-page/error-page.component';
import { CreateRecordService } from './Main/services/create-record.service';
import { UpdateService } from './Main/services/update.service';
import { RecordPipe } from './Main/pipes/record.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { EditRecordService } from './Main/services/edit-record.service';
import { ConfirmDeleteComponent } from './Parts/confirm-delete/confirm-delete.component';
import { MessageSnackBarService } from './Main/services/snack-bar.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ParkingDomainComponent } from './Parts/parking-domain/parking-domain.component';
import { ConfirmDialogComponent } from './Parts/confirm-dialog/confirm-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ChangeGridPrimaryDialogComponent } from './Parts/change-grid-primary-dialog/change-grid-primary-dialog.component';
import { StepperInfoComponent } from './Parts/stepper-info/stepper-info.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatVerticalStepperScrollerDirective } from './Main/directives/mat-vertical-stepper-scroller.directive';
import { HighlightDirective } from './Main/directives/highlight.directive';
import { AuthInterceptor } from './Main/extraModules/auth.interceptor';
import { MatTableResponsiveModule } from './Main/directives/mat-table-responsive/mat-table-responsive.module';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { PolicyComponent } from './Pages/policy/policy.component';
import { ConflictsComponent } from './Pages/conflicts/conflicts.component';
import { ConfirmationGuard } from './Main/extraModules/confirmation.guard';
import { ConfigurationComponent } from './Pages/configuration/configuration.component';
import { ConfigurationService } from './Main/services/configuration.service';
import { BackupService } from './Main/services/backup.service';
import { ThreatIntelComponent } from './Pages/threat-intel/threat-intel.component';
import { SentinelConfigComponent } from './Parts/sentinel-config/sentinel-config.component';
import { SentinelThreatService } from './Main/services/sentinel-threat.service';
import { SentinelTablaComponent } from './Parts/sentinel-tabla/tabla.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GarbageComponent } from './Parts/garbage/garbage.component';
import { GarbageService } from './Main/services/garbage.service';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { InfoipComponent } from './Pages/infoip/infoip.component';
import { TestingComponent } from './Pages/testing/testing.component';
import { NotificationsComponent } from './Parts/notifications/notifications.component';
import { SearchBarComponent } from './Parts/search-bar/search-bar.component';
import { SearchResultComponent } from './Parts/search-result/search-result.component';
import { SearchService } from './Main/services/search.service';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: 'var(--accent-color)',
    },
    highlight: {
      background: 'var(--accent-dark-color)'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{buttons}}'
  },
  revokable: false,
  elements: {
    // buttons: '<span id="cookieconsent:desc" class="cc-message mat-typography ">By using our site, you acknowledge that you have read and understood our Policy <a aria-label="learn more about cookies" role="button" tabindex="0" class="cc-link" href="https://www.cookiesandyou.com" rel="noopener noreferrer nofollow" target="_blank">Learn more</a></span><div class="cc-compliance cc-highlight"><a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn mat-raised-button cc-deny">Decline</a><a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn mat-raised-button cc-allow">Allow cookies</a></div>',
    buttons: '<span id="cookieconsent:desc" class="cc-message mat-typography ">By using our site, you acknowledge that you have read and understood our Policy <a aria-label="learn more about cookies" role="button" tabindex="0" class="cc-link" href="./policy" rel="noopener noreferrer nofollow" target="_blank">Learn more</a></span><div class="cc-compliance cc-highlight"><a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn mat-raised-button cc-allow">Allow cookies</a></div>',
  },
};
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity:true 
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormularioComponent,
    TablaComponent,
    SentinelTablaComponent,
    DashboardComponent,
    ArbolComponent,
    CardComponent,
    SubdomainsComponent,
    SubdomainTablaComponent,
    AddButtonComponent,
    CreateRecordComponent,
    ExampleDashboardComponent,
    ErrorPageComponent,
    RecordPipe,
    ConfirmDeleteComponent,
    HighlightDirective,
    MatVerticalStepperScrollerDirective,
    ParkingDomainComponent,
    ConfirmDialogComponent,
    ChangeGridPrimaryDialogComponent,
    StepperInfoComponent,
    PolicyComponent,
    ConflictsComponent,
    ConfigurationComponent,
    ThreatIntelComponent,
    SentinelConfigComponent,
    GarbageComponent,
    InfoipComponent,
    TestingComponent,
    NotificationsComponent,
    SearchBarComponent,
    SearchResultComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatMenuModule,
    MatTreeModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    MatChipsModule,
    MatSlideToggleModule,
    FormsModule,
    MatBadgeModule,
    MatStepperModule,
    MatTableResponsiveModule,
    MatProgressBarModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxMatTimepickerModule,
  ],
  providers: [
    AuthGuard,
    ConfirmationGuard,
    ConfigurationService,
    GarbageService,
    LoginService,
    MessageSnackBarService,
    CreateRecordService,
    UpdateService,
    BackupService,
    EditRecordService,
    SearchService,
    SentinelThreatService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: myCustomTooltipDefaults 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


<form class="example-form" [formGroup]="newRecordForm">

  <h1 mat-dialog-title>{{editText}} {{data[0]}} record</h1>
  <div mat-dialog-content>
    <p>
    <mat-form-field appearance="fill" *ngIf="data[0]!='PTR' && data[0]!='NS'">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <span matSuffix>.{{data[1]}}</span>
    </mat-form-field>
    </p>
    <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='PTR'">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <span matSuffix>.in-addr.arpa</span>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='A' || data[0]=='PTR'">
      <mat-label>Ipv4addr</mat-label>
      <input matInput formControlName="ipv4addr">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='AAAA'">
      <mat-label>Ipv6addr</mat-label>
      <input matInput formControlName="ipv6addr">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='CNAME'">
      <mat-label>Canonical</mat-label>
      <input matInput formControlName="canonical">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='MX'">
      <mat-label>Mail Exchanger</mat-label>
      <input matInput formControlName="mail_exchanger">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='MX'">
      <mat-label>Preference</mat-label>
      <input matInput type="number" formControlName="preference">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='NS'">
      <mat-label>Nameserver</mat-label>
      <input matInput formControlName="nameserver">
    </mat-form-field>
  </p>
<!--<p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='NS'">
      <mat-label>Addresses</mat-label>
      <input matInput formControlName="addresses">
    </mat-form-field>
  </p>-->
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='PTR'">
      <mat-label>Ptrdname</mat-label>
      <input matInput formControlName="ptrdname">
      <span matSuffix>.{{data[1]}}</span>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='TXT'">
      <mat-label>Text</mat-label>
      <input matInput formControlName="text">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='SRV'">
      <mat-label>Port</mat-label>
      <input matInput type="number" formControlName="port">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='SRV'">
      <mat-label>Priority</mat-label>
      <input matInput type="number" formControlName="priority">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='SRV'">
      <mat-label>Target</mat-label>
      <input matInput formControlName="target">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="data[0]=='SRV'">
      <mat-label>Weight</mat-label>
      <input matInput type="number" formControlName="weight">
    </mat-form-field>
  </p>
  </div>
  <div class="dialog-options" mat-dialog-actions>
    <button mat-button (click)="closeDialog()">No</button>
    <button mat-raised-button color="accent" (click)="createRecord()" [disabled]="!newRecordForm.valid" cdkFocusInitial>Yes</button>
  </div>

</form>

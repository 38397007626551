import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { UpdateService } from '../../Main/services/update.service';
import { SentinelTablaDataSource, SentinelTablaItem } from './tabla-datasource';
import { SentinelThreatService } from 'src/app/Main/services/sentinel-threat.service';

@Component({
  selector: 'app-sentinel-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css']
})
export class SentinelTablaComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<SentinelTablaItem>;
  dataSource: SentinelTablaDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['description', 'value', 'os', 'source', 'last_update'];

  constructor(
    private sentinelThreatService: SentinelThreatService,
    public dialog: MatDialog,
    private updateService: UpdateService,
  ) {
    this.dataSource = new SentinelTablaDataSource(sentinelThreatService, updateService);
  }

  ngAfterViewInit(): void {
    this.sort.sort(({ id: 'last_update', start: 'desc'}) as MatSortable);

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 960;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

}

<div>
  <table mat-table class="full-width-table table-responsive" matSort matTableResponsive aria-label="Elements">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">
        {{row.name}}
        <mat-chip-list class="small-chip" *ngIf="row.external_primaries?.length>0"
          [class.display-none]="row.name==null">
          <mat-chip color="accent" selected>External</mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row" title>
        <span *ngIf="row.name!=null">
          <button mat-icon-button color="primary" aria-label="Edit subdomain" matTooltip="Edit subdomain"
            (click)="edit_domain(row)">
            <mat-icon>edit</mat-icon>
          </button>



          <button mat-icon-button color="accent" aria-label="Parking" matTooltip="Parking domain"
            (click)="parking(row)">
            <mat-icon>local_parking</mat-icon>
          </button>
          <button mat-icon-button color="special-2-text" aria-label="Template domain" matTooltip="Apply template"
            (click)="template(row)">
            <mat-icon>developer_board</mat-icon>
          </button>

          <button *ngIf="!row.disable" mat-icon-button color="warn" aria-label="Disable domain"
            matTooltip="Disable domain" (click)="disable_zone(row, true)">
            <mat-icon>domain_disabled</mat-icon>
          </button>
          <button *ngIf="row.disable" mat-icon-button aria-label="Enable domain" matTooltip="Enable domain"
            (click)="disable_zone(row, false)">
            <mat-icon>domain</mat-icon>
          </button>

          <button mat-icon-button color="special-text" aria-label="Change Grid Primary" matTooltip="Change Name Server"
            (click)="changeNS(row)">
            <mat-icon>sell</mat-icon>
          </button>


        </span>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'disable-row-style': row.disable }"
      [class.display-none]="row.name==null && isMobile"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>
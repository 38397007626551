<mat-stepper matVerticalStepper orientation="vertical" [linear]="isLinear" #stepper>
    <mat-step [stepControl]="initFormGroup" label="first">
        <form [formGroup]="initFormGroup">
            <ng-template matStepLabel>Welcome!</ng-template>
            <div>
                <h2>Welcome to the DNS User Portal for Infoblox Systems</h2>
                <p>With the help of this dashboard, you will be able to access, manage and edit your assigned domains
                    and subdomains.</p>
                <p></p>
            </div>
            <div class="next-buttons">
                <button color="accent" mat-raised-button matStepperNext >Next</button>

                <!--<button mat-button (click)="stepper.reset()">Reset</button>-->
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="firstFormGroup" label="second">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Manage your domains</ng-template>
            <div>
                <img src="../../assets/img/dashboard.png" id="dashboard-info" alt="dashboard-image"
                    aria-label="dashboard-image">
                <p>
                    On the Home Screen you can see the list of all the domains that a particular user has access to. The
                    list can be sorted in reverse alphabetical order if necessary. On the right side there are the
                    different action icons with following functions:
                </p>

                <mat-list role="list">
                    <mat-list-item role="listitem">
                        <mat-icon color="primary">edit</mat-icon>
                        <div mat-line><b>Edit:</b> </div>
                        <div mat-line>
                            Allows you to access the domain editing screen
                            (see Step 2 tab) where you can modify several aspects of the selected domain.
                        </div>

                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <mat-icon color="accent">local_parking</mat-icon>
                        <div mat-line><b>Parking:</b></div>
                        <div mat-line>Puts the domain in parking mode,
                            pointing to a user definable URL or IP address.</div>

                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <mat-icon color="special-2-text">developer_board</mat-icon>
                        <div mat-line><b>Templates:</b></div>
                        <div mat-line>Enables applying a
                            predefined template to the selected domain, with predefined values for different services
                            (mail,
                            smtp, etc.).</div>

                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <mat-icon color="warn">domain_disabled</mat-icon>
                        <div mat-line>
                            <b>Disable:</b>
                        </div>
                        <div mat-line>Disables the selected domain
                            completely.</div>
                    </mat-list-item>

                    <mat-list-item role="listitem">
                        <mat-icon color="special-text">sell</mat-icon>
                        <div mat-line><b>Change name servers:</b> </div>
                        <div mat-line> Allows to change the Grid Primary, Grid Secondary, External Primary and
                            secondary, External Address Name Servers, etc. of
                            the selected domain.</div>
                    </mat-list-item>
                </mat-list>
                <p></p>
            </div>
            <div class="next-buttons">
                <button color="accent" mat-raised-button matStepperNext>Next</button>

                <button mat-raised-button matStepperPrevious>Back</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="third">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>And also your subdomains</ng-template>
            <div>
                <img src="../../assets/img/subdomains.png" id="domain-info" alt="domain-image"
                    aria-label="domain-image">
                <p>
                    Once a domain is selected on the Home Screen, you access the different properties and values
                    available for that domain. The displayed list can be sorted in different ways, in order to
                    accomodate user needs when looking for a certain domain. All the relevant configured values can be
                    seen at once.
                </p>
                <p>
                    On the right side are the editing icons that allow you to inspect and change any of the properties
                    of
                    a certain domain. These are the available options:
                </p>


                <mat-list role="list">
                    <mat-list-item role="listitem">
                        <mat-icon color="primary">edit</mat-icon>
                        <div mat-line><b>Edit:</b></div>
                        <div mat-line>When you click on the Edit icon, a popup menu is
                            displayed with all the different records that can be changed for that particular subdomain
                            (see
                            below).</div>


                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <mat-icon color="accent">domain_disabled</mat-icon>
                        <div mat-line><b>Disable:</b></div>
                        <div mat-line>Disables the subdomain so it cannot
                            be used, but allows for re-enabling it at a later point.</div>

                    </mat-list-item>
                    <mat-list-item role="listitem">
                        <mat-icon color="warn">delete</mat-icon>
                        <div mat-line><b>Delete:</b></div>
                        <div mat-line> Deleted the subdomain and all of its associated
                            values completely.</div>

                    </mat-list-item>
                </mat-list>
            </div>
            <div class="next-buttons">
                <button color="accent" mat-raised-button matStepperNext >Next</button>

                <button mat-raised-button matStepperPrevious>Back</button>
                <!--<button mat-button (click)="stepper.reset()">Reset</button>-->
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="thirdFormGroup" label="fourth">
        <form [formGroup]="thirdFormGroup">
            <ng-template matStepLabel>Create new subdomains</ng-template>
            <div>
                <img src="../../assets/img/add-button.png" id="create-info" alt="create-record-image"
                    aria-label="create-record-image">
                <p>
                    To add a new subdomain please click on the "Add" button in the lower right corner of the screen:
                </p>
                <img src="../../assets/img/add-record.png" id="create-info" alt="create-record-image"
                    aria-label="create-record-image">

                <p>
                    In the dialog box that opens, you can write the values you want to assign to that particular
                    subdomain (MX records in our example). Once you have filled in all needed values, click on "Ok" to
                    save and apply them to the Infoblox system.
                </p>
                <p>
                    "Close" simply closes the dialog without saving any changes, nor creating a new record.
                </p>


            </div>
            <div class="next-buttons">
                <button color="accent" mat-raised-button matStepperNext >Next</button>

                <button mat-raised-button matStepperPrevious>Back</button>

                <!--<button mat-button (click)="stepper.reset()">Reset</button>-->
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="fourthFormGroup" label="fifth">
        <form [formGroup]="fourthFormGroup">
            <ng-template matStepLabel>Edit a subdomains</ng-template>
            <div>
                <p>
                    When clicking on the Edit icon for a subdomain, the following menu pops up:
                </p>

                <img src="../../assets/img/edit-record.png" id="edit-info" alt="edit-record-image"
                    aria-label="edit-record-image">

                <p>
                    Here you can select which type of record you want to inspect or edit, by clicking on the appropriate
                    entry. This shows a dialog like the following (for MX records, in this particular case) where you
                    can change the associated values if needed.
                </p>
                <p>
                    Close simply closes the dialog without saving any
                    changes, and Ok does the same, but saving the possible modifications.
                </p>
            </div>
            <div class="next-buttons">
                <button color="accent" mat-raised-button matStepperNext >Next</button>

                <button mat-raised-button matStepperPrevious>Back</button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="fifthFormGroup" label="sixth">
        <form [formGroup]="fifthFormGroup">
            <ng-template matStepLabel>Notes</ng-template>
            <div>
                <h5>
                    <i>
                        Note: In the current version of the portal the comments visible in the Comment column are
                        generated automatically by the portal when editing the properties of a subdomain. In future
                        releases there will be an option for adding custom user comments.
                    </i>
                </h5>
            </div>
            <div class="next-buttons">
                <button mat-button (click)="stepper.reset()">Reset</button>

                <button mat-raised-button matStepperPrevious>Back</button>

            </div>
        </form>
    </mat-step>
</mat-stepper>
import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subdomains',
  templateUrl: './subdomains.component.html',
  styleUrls: ['./subdomains.component.css']
})
export class SubdomainsComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 2,
          height: "275px",
          tabla: { cols: 2, rows: 2 },
          miniCard: { cols: 2, rows: 1 },
          form: { cols: 2, rows: 3 }
        };
      }
 
     return {
        columns: 2,
        height: "385px",
        tabla: { cols: 2, rows: 2 },
        miniCard: { cols: 1, rows: 1 },
        form: { cols: 1, rows: 2 }
      };
    })
  );
  subdomain=""
  primary=false;

  constructor(private breakpointObserver: BreakpointObserver,  private route: ActivatedRoute) {


  }

  ngOnInit() {
    this.subdomain = this.route.snapshot.paramMap.get('id') || "";
    this.primary = this.route.snapshot.paramMap.get('primary') == "true" || false;

    //this.hero$ = this.service.getHero(heroId);
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/AppSettings';

@Injectable()
export class SearchService {

  private searchURLBase = AppSettings.API_ENDPOINT;


  constructor(private http: HttpClient) { }


  search(search_term:string){
    const url = this.searchURLBase + "search";

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
    };

    return this.http.post(
      url,
      JSON.stringify(search_term),
      httpOptions
    );
  }

  getRecordData(ref:string){
    const url = this.searchURLBase + "search-record" + "?ref="+ref;

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
    };

    return this.http.get(
      url,
      httpOptions
    );
  }

}

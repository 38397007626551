import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDeleteComponent } from 'src/app/Parts/confirm-delete/confirm-delete.component';
import { CreateRecordComponent } from 'src/app/Parts/create-record/create-record.component';
import { EditRecordService } from 'src/app/Main/services/edit-record.service';
import { LoginService } from 'src/app/Main/services/login.service';
import { UpdateService } from 'src/app/Main/services/update.service';

import { SubdomainTablaDataSource, SubdomainTablaItem } from './subdomains-tabla-datasource';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-subdomain-tabla',
  templateUrl: './subdomains-tabla.component.html',
  styleUrls: ['./subdomains-tabla.component.css']
})
export class SubdomainTablaComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<SubdomainTablaItem>;
  dataSource: SubdomainTablaDataSource;

  @Input() subdomain:string="";
  @Input() primary:boolean=false;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name','creator','comment','type','record', 'actions'];

  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private editRecordService: EditRecordService,
    private updateService: UpdateService
    ) {
    this.dataSource = new SubdomainTablaDataSource(loginService, route, updateService);
  }
  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 960;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  disableRecord(row:any){

    let text = "This will disable the record ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text]
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.disableRecord(true, row.record._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
            },
            error=>{}
          )
        }
      }
    });
  }

  enableRecord(row:any){

    let text = "This will enable the record ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text]
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.disableRecord(false, row.record._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
            },
            error=>{}
          )
        }
      }
    });
  }

  deleteRecord(row:any){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: [row.name],
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if(result[0]!== "close"){
          this.editRecordService.deleteRecord(row.record._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
            },
            error=>{}
          )
        }
      }
    });
  }

  editRecord(row:any){
    const dialogRef = this.dialog.open(CreateRecordComponent, {
      data: [row.parsedType.split(":")[1].toUpperCase(), this.subdomain, true, row],
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if(result[0]== "editRecord"){
          this.editRecordService.editRecord(result[1][1], row.record._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
            },
            error=>{}
          );
        }
      }
    });

  }



}

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SearchService } from 'src/app/Main/services/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {


  @ViewChild('searchbar') searchbar!: ElementRef;
  searchText = '';
  @Input() toggleSearch: boolean = false;
  @Output() toggleSearchChange=new EventEmitter();


  searchResults:Array<any>=[];

  searching=false;

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.toggleSearch){
      this.searchbar.nativeElement.focus();
    }

  }

  @HostListener('document:click', ['$event'])
  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
    this.toggleSearchChange.emit(false)
  }

  sendSearch(){
    if(this.searchText.length>0){
      this.searching=true;
      this.searchResults=[]
      this.searchService.search(this.searchText).subscribe(
        (result:any)=> {
          if(result.length!=0){
              let modded_array = result.map(function (element: any) {
                return {
                  ...element,
                  parsedType: element?._ref.split("/")[0]
                }
              })
              this.searchResults=modded_array;
          }else{
            this.searchResults=[ {name:"No data found"} ];
          }
        },
        error=>{},
        ()=>{
          this.searching=false
        }

      )
    }
  }
}

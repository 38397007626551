import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChangeGridPrimaryDialogComponent } from '../change-grid-primary-dialog/change-grid-primary-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ParkingDomainComponent } from '../parking-domain/parking-domain.component';
import { EditRecordService } from '../../Main/services/edit-record.service';
import { LoginService } from '../../Main/services/login.service';
import { UpdateService } from '../../Main/services/update.service';
import { MessageSnackBarService } from '../../Main/services/snack-bar.service';
import { TablaDataSource, TablaItem } from './tabla-datasource';

@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css']
})
export class TablaComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<TablaItem>;
  dataSource: TablaDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'actions'];

  pageSize=10;

  constructor(
    private loginService: LoginService,
    private router: Router,
    public dialog: MatDialog,
    private editRecordService: EditRecordService,
    private messageSnackBarService: MessageSnackBarService,
    private updateService: UpdateService,
  ) {
    this.dataSource = new TablaDataSource(loginService, updateService);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 960;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  edit_domain(row: any) {
    let primary = true;
    if (row.external_primaries?.length > 0) {
      primary = false;
    }
    this.router.navigate(['/subdomains', { id: row.name, primary: primary }]);
  }

  parking(row: any) {
    let text = "This will change the parking of your domain ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text],
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.parkingDomain(row.name).subscribe(
            (success: any) => {
              let text_result = success.map((response: any) => {
                return response.text
              }).filter(Boolean).join("\n")

              this.messageSnackBarService.createDirectMessage("Parking modified \n" + text_result, 5000);
            },
            error => { }
          )

        }
      }
    });
  }

  template(row: any) {

    let text = "This will create and modify records, any conflict with different record types will be omitted, ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text]
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.applyTemplate(row.name).subscribe(
            (success: any) => {
              let text_result = success.map((response: any) => {
                return response.text
              }).filter(Boolean).join("\n")

              this.messageSnackBarService.createDirectMessage("Template created \n" + text_result, 5000);

            }
          )

        }
      }
    });
  }

  disable_zone(row: any, disable: boolean) {
    let text = "This will change if the domain is enabled or disabled ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text]
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.disableZone(disable, row._ref).subscribe(
            success => {
              this.updateService.sendUpdateEventZones();
            }
          )
        }
      }
    });
  }

  changeNS(row: any) {
    const dialogRef = this.dialog.open(ChangeGridPrimaryDialogComponent, {
      data: [row]
    } as MatDialogConfig);


    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirmChange") {

          this.editRecordService.editNSZone(result[2], result[1]).subscribe(
            (success: any) => {
              this.updateService.sendUpdateEventZones();
            }
          )
        }
      }
    });
  }


}

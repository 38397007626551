<div >
  <table mat-table class="full-width-table table-responsive" matSort matTableResponsive aria-label="Elements">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.name!=null">{{row.name}}.{{subdomain}}</span>
        <mat-chip-list class="small-chip" *ngIf="!primary" [class.display-none]="row.name==null">
          <mat-chip color="accent" selected *ngIf="!primary">External</mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>
    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Creator</th>
      <td mat-cell *matCellDef="let row">
        <span class="creator-cell">
          {{row.creator}} <mat-icon color='primary' *ngIf="row.creator=='SYSTEM'">locker</mat-icon>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
      <td mat-cell *matCellDef="let row">{{row.comment}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">{{row.type!='UNSUPPORTED'?row.type: row.parsedType }}</td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let row">{{row.address}}</td>
    </ng-container>
    <ng-container matColumnDef="disable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Disabled</th>
      <td mat-cell *matCellDef="let row">{{row.disable}}</td>
    </ng-container>
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>View</th>
      <td mat-cell *matCellDef="let row">{{row.view}}</td>
    </ng-container>
    <ng-container matColumnDef="zone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zone</th>
      <td mat-cell *matCellDef="let row">{{row.zone}}</td>
    </ng-container>
    <ng-container matColumnDef="_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ref</th>
      <td mat-cell *matCellDef="let row">{{row._ref}}</td>
    </ng-container>
    <ng-container matColumnDef="record">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>More</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.type=='record:a'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-1" selected>
              <b class="keep-all">Ip V4:</b> {{row.record?.ipv4addr}}
            </mat-chip>
          </mat-chip-list>
        </span>
        <span *ngIf="row.type=='record:aaaa'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-2" selected>
              <b class="keep-all">Ip V6:</b> {{row.record?.ipv6addr}}
            </mat-chip>
          </mat-chip-list>
        </span>
        <span *ngIf="row.type=='record:txt'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-3" selected>
              <b class="keep-all">Text:</b> {{row.record?.text}}
            </mat-chip>
          </mat-chip-list>
        </span>
        <span *ngIf="row.type=='record:cname'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-4" selected>
              <b class="keep-all">Canonical:</b> {{row.record?.canonical}}
            </mat-chip>
          </mat-chip-list>
        </span>
        <span *ngIf="row.type=='record:mx'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-5" selected>
              <b class="keep-all">Mail Exchanger:</b> {{row.record?.mail_exchanger}}
            </mat-chip>
            <mat-chip color="spectrum-6" selected>
              <b class="keep-all">Preference:</b> {{row.record?.preference}}
            </mat-chip>
          </mat-chip-list>
        </span>
        <span *ngIf="row.type=='record:srv'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-7" selected>
              <b class="keep-all">Port:</b> {{row.record?.port}}
            </mat-chip>
            <mat-chip color="spectrum-8" selected>
              <b class="keep-all">Priority:</b> {{row.record?.priority}}
            </mat-chip>
            <mat-chip color="spectrum-9" selected>
              <b class="keep-all">Target:</b> {{row.record?.target}}
            </mat-chip>
            <mat-chip color="spectrum-10" selected>
              <b class="keep-all">Weight:</b> {{row.record?.weight}}
            </mat-chip>
          </mat-chip-list>
        </span>
        <span *ngIf="row.type=='UNSUPPORTED'">
          <mat-chip-list class="">
            <mat-chip color="spectrum-10" selected *ngIf="row.record?.nameserver">
              <b class="keep-all">Nameserver:</b> {{row.record?.nameserver}}
            </mat-chip>
          </mat-chip-list>
        </span>


      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row" title>
        <span *ngIf="row.name!=null && row.creator!='SYSTEM' && primary" class="icons-list">

          <button mat-icon-button (click)="editRecord(row)" color="primary" aria-label="Edit subdomain"
            matTooltip="Edit subdomain">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="disableRecord(row)" color="accent" aria-label="Subdomain disable icon"
            matTooltip="Disable subdomains" *ngIf="!row.disable">
            <mat-icon>domain_disabled</mat-icon>
          </button>
          <button mat-icon-button (click)="enableRecord(row)" aria-label="Subdomain enable icon"
            matTooltip="Enable subdomain" *ngIf="row.disable">
            <mat-icon>domain</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteRecord(row)" color="warn" aria-label="Delete subdomain"
            matTooltip="Delete subdomain">
            <mat-icon>delete</mat-icon>
          </button>

        </span>

        <!--<span *ngIf="row.creator=='SYSTEM'">
          <mat-icon matTooltip="System generated records can't be modified" color='primary'>locker</mat-icon>
        </span>-->

        <span *ngIf="!primary">
          <mat-icon matTooltip="Secondary domains can't be modified" color='primary'>locker</mat-icon>
        </span>

        <!--<button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
          <mat-icon>open_in_new</mat-icon>
        </button>-->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'disable-row-style': row.disable }"
      [class.display-none]="row.name==null && isMobile"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>
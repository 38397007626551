import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationComponent } from './Pages/configuration/configuration.component';
import { AuthGuard } from './Main/extraModules/auth.guard';
import { ConflictsComponent } from './Pages/conflicts/conflicts.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { ErrorPageComponent } from './Pages/error-page/error-page.component';
import { LoginComponent } from './Pages/login/login.component';
import { PolicyComponent } from './Pages/policy/policy.component';
import { SubdomainsComponent } from './Pages/subdomains/subdomains.component';
import { ThreatIntelComponent } from './Pages/threat-intel/threat-intel.component';
import { GarbageComponent } from './Parts/garbage/garbage.component';
import { InfoipComponent } from './Pages/infoip/infoip.component';
import { TestingComponent } from './Pages/testing/testing.component';

const routes: Routes = [
  {path: '', redirectTo: '/domains', pathMatch: 'full'},
  {path: 'policy',  component: PolicyComponent},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'domains', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'subdomains', component: SubdomainsComponent, canActivate: [AuthGuard]},
  // Comment the following to keep only dns features
  // {path: 'conflicts', component: ConflictsComponent, canActivate: [AuthGuard]},
  // {path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard]},
  // {path: 'threat-intel', component: ThreatIntelComponent, canActivate: [AuthGuard]},
  // {path: 'info-ip', component: InfoipComponent, canActivate: [AuthGuard]},
  //
  {path: '**', component: ErrorPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, HostListener, Input, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SearchService } from 'src/app/Main/services/search.service';
import { CreateRecordComponent } from '../create-record/create-record.component';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditRecordService } from 'src/app/Main/services/edit-record.service';
import { UpdateService } from 'src/app/Main/services/update.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MessageSnackBarService } from 'src/app/Main/services/snack-bar.service';


@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SearchResultComponent implements OnInit {

  @Input() searchResults:any=[];
  displayedColumns: string[] = ["name", "type"];

  expandedElement: any | null;

  activeRecord;

  constructor(
    private searchService: SearchService,
    public dialog: MatDialog,
    private editRecordService: EditRecordService,
    private messageSnackBarService: MessageSnackBarService,
    private updateService: UpdateService
    ) { }

  ngOnInit(): void {
  }

  ngOnChange(){
    console.log(this.searchResults)
  }

  @HostListener('document:click', ['$event'])
  closeWindow(){
    this.searchResults=[]
  }


  getRecordData(ref){

    this.activeRecord=null;
    this.searchService.getRecordData(ref).subscribe(
      success=>{
        this.activeRecord=success;
      }
    )
    
  }


  disableRecord(row:any){

    let text = "This will disable the record ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text]
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.disableRecord(true, row._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
              this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);

            },
            error=>{}
          )
        }
      }
    });
  }

  enableRecord(row:any){

    let text = "This will enable the record ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: [text]
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result != null) {
        if (result[0] === "confirm") {
          this.editRecordService.disableRecord(false, row._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
              this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);

            },
            error=>{}
          )
        }
      }
    });
  }

  deleteRecord(row:any){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: [row.name],
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if(result[0]!== "close"){
          this.editRecordService.deleteRecord(row._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
              this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);

            },
            error=>{}
          )
        }
      }
    });
  }

  editRecord(row:any){
    let parsedRow=row;
    parsedRow["record"]=this.activeRecord

    const dialogRef = this.dialog.open(CreateRecordComponent, {
      data: [row.parsedType.split(":")[1].toUpperCase(), parsedRow["zone"], true, parsedRow],
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if(result[0]== "editRecord"){
          this.editRecordService.editRecord(result[1][1], row._ref).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
              this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);
            },
            error=>{}
          );
        }
      }
    });

  }




}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateRecordComponent } from '../create-record/create-record.component';
import { UpdateService } from '../../Main/services/update.service';
import { CreateRecordService } from '../../Main/services/create-record.service';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.css']
})
export class AddButtonComponent implements OnInit {

  typeList=['A', 'AAAA', 'CNAME', 'MX', 'PTR', 'SRV', 'TXT' ];

  @Input() subdomain:string="";

  constructor(
    public dialog: MatDialog,
    private createRecordService: CreateRecordService,
    private updateService: UpdateService
    ) { }

  ngOnInit(): void {
  }

  createRegister(type:string){
    const dialogRef = this.dialog.open(CreateRecordComponent, {
      data: [type, this.subdomain, false],
    } as MatDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if(result[0]!== "close"){
          this.createRecordService.createRecord(result[1][1], result[1][0]).subscribe(
            success=> {
              this.updateService.sendUpdateEvent();
            },
            error=>{}
          )
        }


      }
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-parking-domain',
  templateUrl: './parking-domain.component.html',
  styleUrls: ['./parking-domain.component.css']
})
export class ParkingDomainComponent implements OnInit {

  parkingForm: FormGroup;

  ipv4Pattern='^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'


  constructor(
    public dialogRef: MatDialogRef<ParkingDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  )
  { 
    this.parkingForm=this.formBuilder.group({
      ipv4addr: ["", [Validators.required, Validators.pattern(this.ipv4Pattern)]],
    });
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close(['close', null]);
  }

  parking(){
    this.dialogRef.close(['parking', [this.data[0], this.parkingForm.value]]);
  }

}

<div class="grid-container">
    <h1 class="mat-h1">Information</h1>
    <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="{{( cardLayout | async )?.height}}">
        <mat-grid-tile [colspan]="( cardLayout | async )?.side?.cols" 
                        [rowspan]="( cardLayout | async )?.side?.rows">
            <app-card class="top-align" title="Ip lookup">
    
                <div>
                <form class="example-form" [formGroup]="ipForm">

                    <mat-form-field appearance="fill">
                        <mat-label>IP</mat-label>
                        <input matInput formControlName="ipData">
                    </mat-form-field>

                    <button mat-raised-button [disabled]="!ipForm.valid" (click)="searchIp()" color="accent">Search</button>

                </form>
                </div>

                <div class="terminal full-height">
                    <div class="data-text" *ngFor="let item of ipData;let indice=index">
                        <b>{{item[0]}}: </b>
                        <i>{{item[1]}}</i>
                    </div>
                </div>
            </app-card>
        </mat-grid-tile>

        <!-- <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" 
        [rowspan]="( cardLayout | async )?.tabla?.rows">
            <app-card title="">

                <div class="data-text" *ngFor="let item of ipData;let indice=index">
                    <b>{{item[0]}}: </b>
                    <i>{{item[1]}}</i>
                </div>

            </app-card>
        </mat-grid-tile> -->
  
    </mat-grid-list>
  </div>
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { BackupService } from 'src/app/Main/services/backup.service';
import { ConfigurationService } from 'src/app/Main/services/configuration.service';
import { MessageSnackBarService } from 'src/app/Main/services/snack-bar.service';
import { LoginService } from 'src/app/Main/services/login.service';

import * as Forge from 'node-forge';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  excludeCleanForm: FormGroup;
  blockPage=false;
  backupList;
  selectedBackup;

  publicKey="";

  @ViewChild('backups') backups;


  ipv4Pattern='^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
  networkPattern='(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-2]{0,1}[0-9]{0,1}'
  networkListPattern='^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-2]{0,1}[0-9]{0,1})(\,(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-2]{0,1}[0-9]{0,1})*$'

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 3,
          tabla: { cols: 3, rows: 3 },
          tablaBottom: { cols: 3, rows: 4 },
          miniCard: { cols: 3, rows: 10 }
        };
      }
 
     return {
        columns: 3,
        tabla: { cols: 1, rows: 3 },
        tablaBottom: { cols: 1, rows: 4 },
        miniCard: { cols: 1, rows: 7 }
      };
    })
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private messageSnackBarService: MessageSnackBarService,
    private loginService: LoginService,
    private backupService: BackupService
    ) {

    this.getPublicKey();

    window.addEventListener('beforeunload', (event) => {
      if(this.blockPage){
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }
    });



    this.excludeCleanForm=this.formBuilder.group({
      include: ["", [Validators.required, Validators.pattern(this.networkListPattern)]],
      exclude: ["", [Validators.required, Validators.pattern(this.networkListPattern)]],
      // sentinelIp: [""],
      // sentinelAPIKey: [""]
    })
   }

  ngOnInit(): void {
    this.configurationService.getConfigData().subscribe(
      success=>{
        // console.log(success)
        this.excludeCleanForm.controls['include'].setValue(success['include'])
        this.excludeCleanForm.controls['exclude'].setValue(success['exclude'])
        // this.excludeCleanForm.controls['sentinelIp'].setValue(success['sentinelIp'])
        // this.excludeCleanForm.controls['sentinelAPIKey'].setValue(success['sentinelAPIKey'])

      
      }
    )

    this.getBackupList();
  }

  confirmIncludeChanges(){
    // console.log(this.excludeCleanForm.value)
    // console.log(JSON.stringify(this.excludeCleanForm.value))

    // let codedApiKey=this.codePass(this.excludeCleanForm.controls['sentinelAPIKey'].value)
    // console.log(codedApiKey)
    // this.excludeCleanForm.controls['sentinelAPIKey'].setValue(codedApiKey)

    let data=this.excludeCleanForm.value
    Object.keys(data).forEach(k => (!data[k] && data[k] !== undefined) && delete data[k]);

    // if(data['sentinelAPIKey']){
      // data['sentinelAPIKey']=this.codePass(data['sentinelAPIKey'])
    // }


    this.configurationService.editChanges(data).subscribe(
      success=>{
        this.messageSnackBarService.createDirectMessage("Configuration saved", 5000);

      }
    );

  }

  createBackup(){

    this.blockPage=true;

    this.messageSnackBarService.createDirectMessage("asking for backup, please don't close this window", 5000);

    this.backupService.createBackup().subscribe(
      success=>{
        this.blockPage=false;
        this.messageSnackBarService.createDirectMessage("Backup Stored", 5000);
        this.getBackupList();
      }
    );
  }

  getBackupList(){
    this.backupService.getList().subscribe(
      success=>{
        // console.log(success);
        this.backupList=success;
      }
    );
  }

  downloadBackup(){
    this.selectedBackup=this.backups.selectedOptions.selected[0]?.value

    this.backupService.downloadBackup(this.selectedBackup).subscribe(
      (success:any)=>{         
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(success)
        a.href = objectUrl
        a.download = this.selectedBackup;
        a.click();
        URL.revokeObjectURL(objectUrl);
      },
      error=>{
        this.messageSnackBarService.createDirectMessage("Error downloading file", 5000);
      }
    )
  }


  getPublicKey(){
    this.loginService.getPublicKey().subscribe(
      (data) => {
        this.publicKey = data.toString();
      })
  }


  codePass(pass){
    const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    const pass_rsa=Forge.util.encode64(rsa.encrypt(pass.toString(), 'RSA-OAEP'));

    return pass_rsa

  }
}

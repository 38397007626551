import { Component, ElementRef, enableProdMode, HostListener, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { AppSettings } from './AppSettings';
import { UpdateService } from './Main/services/update.service';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DNS Portal';

  lightTheme: boolean = localStorage.getItem('light-theme') == "true";

  logoRoute = "assets/logo.png" + "?timestamp=" + Date.now().toString();

  isLogged = false;

  userName = localStorage.getItem('userName') || "";
  userCap = this.userName[0]?.toUpperCase() || "";

  menuList = [
    {name:"domains", link:"domains", adminNeeded: false, icon: "space_dashboard"},
    // {name:"conflicts", link:"conflicts", adminNeeded: true, icon: "lan"},
    // {name:"information", link:"info-ip", adminNeeded: true, icon: "info"},
    // {name:"threat intel" , link:"threat-intel", adminNeeded: true, icon: "dynamic_form"},
    // {name:"configuration", link:"configuration", adminNeeded: true, icon: "settings"},
  ];

  badgeNumber:number=0;

  isAdmin = sessionStorage.getItem('userType');

  toggleSearch: boolean = false;

  private popupOpenSubscription: Subscription = new Subscription;
  private popupCloseSubscription: Subscription = new Subscription;
  private initializeSubscription: Subscription = new Subscription;
  private statusChangeSubscription: Subscription = new Subscription;
  private revokeChoiceSubscription: Subscription = new Subscription;
  private noCookieLawSubscription: Subscription = new Subscription;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger | undefined;

  constructor(
    private ccService: NgcCookieConsentService,
    private updateService:UpdateService,
    private breakpointObserver: BreakpointObserver,
    private router: Router) {
    if (this.lightTheme) {
      document.body.className = 'mat-typography';
    } else {
      document.body.className = 'mat-typography ventaone-dark-theme';
    }


    this.updateService.getBadgeUpdate().subscribe(
      (result: number) => {
        this.changeBadgeNumber(result)
      }       
    )
  }

  handleClickSound() {
    let x = <HTMLVideoElement>document.getElementById("myAudio");
    x.play();
  }
  ngOnInit() {

    console.log(AppSettings.VERSION);
    enableProdMode();
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  logout() {
    sessionStorage.removeItem('userPassword');
    sessionStorage.removeItem('userType');

    localStorage.removeItem('userName');

    this.router.navigate(['/login']);

  }

  loggingRenew() {
    this.isLogged = sessionStorage.getItem('userPassword') != null;
    this.userName = localStorage.getItem('userName') || "";
    this.userCap = this.userName[0]?.toUpperCase() || "";
  }

  changeColor() {
    if (this.lightTheme) {
      document.body.className = 'mat-typography ventaone-dark-theme';
      this.lightTheme = false;
      localStorage.setItem('light-theme', "false");
    } else {
      document.body.className = 'mat-typography';
      this.lightTheme = true;
      localStorage.setItem('light-theme', "true");

    }
  }

  hasAdminPermissions(){
    return sessionStorage.getItem('userType') == 'true';
  }

  changeBadgeNumber(number: number){
    this.badgeNumber=number;
  }

  openSearch() {
    this.toggleSearch = true; 
    this.matMenuTrigger?.closeMenu();
  }

}

import {environment} from '../environments/environment';
/**
 * General data
 */
export class AppSettings {
  /**
   * General api url
   * @type {string}
   */
  public static API_ENDPOINT = environment.API_ENDPOINT;

  /**
   * Version data
   * @type {string}
   */
  public static VERSION = 'v1.1-20230227';

}

<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>Log in</mat-card-title>
      </mat-card-header>
      <form class="example-form" [formGroup]="loginForm" (ngSubmit)="login()">
        <mat-card-content>
          <mat-form-field class="example-full-width" >
            <input matInput placeholder="Username" formControlName="username" autofocus>
          </mat-form-field>
          <mat-form-field class="example-full-width" >
            <input matInput placeholder="Password" type="password" formControlName="password">
          </mat-form-field>
        </mat-card-content>
        <button mat-stroked-button color="accent" class="btn-block" type="submit" [disabled]="!loginForm.valid">Log in</button>
      </form>
    </mat-card>
  </div>
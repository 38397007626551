

<div class="grid-container">
    <h1 class="mat-h1">Threat Intel</h1>

    <mat-grid-list cols="{{( cardLayout | async )?.columns}}" rowHeight="85px">

        <mat-grid-tile [colspan]="( cardLayout | async )?.mid?.cols" [rowspan]="( cardLayout | async )?.mid?.rows">
            <app-card>
              <div class="repartir">
                <mat-form-field appearance="fill">
                  <mat-label>Select system</mat-label>
                  <mat-select [(value)]="selected">
                    <mat-option *ngFor="let system of systems" [value]="system.value">
                      {{system.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="center-align">
                  <button mat-raised-button color="accent" (click)="showProgress=true; showDoubleProgress=false" class="btn-block">Transfer</button>
                  <button mat-raised-button color="accent" (click)="showProgress=true; showDoubleProgress=true" class="btn-block">Synchronize</button>
                </div>

                <mat-form-field appearance="fill">
                  <mat-label>Select system</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let system of systems" [value]="system.value">
                      {{system.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              
              <mat-progress-bar *ngIf="showProgress" mode="indeterminate"></mat-progress-bar>
              <mat-progress-bar *ngIf="showDoubleProgress" mode="query"></mat-progress-bar>


            </app-card>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
          <app-card >
            <app-sentinel-tabla></app-sentinel-tabla>

          </app-card>
        </mat-grid-tile>
          <!-- <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
            <app-card >
            </app-card>
          </mat-grid-tile> -->
          <!-- <mat-grid-tile [colspan]="( cardLayout | async )?.tabla?.cols" [rowspan]="( cardLayout | async )?.tabla?.rows">
            <app-card>
  
            </app-card>
          </mat-grid-tile> -->
    </mat-grid-list>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, concat, forkJoin, from, interval, tap } from 'rxjs';
import { LoginService } from '../../Main/services/login.service';
import { MessageSnackBarService } from '../../Main/services/snack-bar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  // @Output() loggingEvent = new EventEmitter<string>();

  savedName: string;

  publicKey = ""

  constructor(
    private loginService: LoginService,
    private messageSnackBarService: MessageSnackBarService,
    private formBuilder: FormBuilder,
    private router: Router) {
    this.savedName = localStorage.getItem("userName") || "";
    this.loginForm = this.formBuilder.group({
      username: [this.savedName, [Validators.required]],
      password: ["", [Validators.required]]
    })
  }

  ngOnInit(): void {
  }

  login() {
    this.loginService.getPublicKey().subscribe(
      (data) => {
        this.publicKey = data.toString();
        this.loginService.checkLogin(this.loginForm.value.username, this.loginForm.value.password, this.publicKey).subscribe(
          (success:any) => {
            //console.log(success)
            this.router.navigate(['/domains']);
            sessionStorage.setItem("userType", (success==1?"true": "false"))

            // this.loggingEvent.emit("true");
          },
          error => {
            //this.messageSnackBarService.createDirectMessage(error.error.detail, 5000);
            this.messageSnackBarService.createDirectMessage(JSON.stringify(error.error.detail), 5000);
          }
        )
      }
    )
  }
}

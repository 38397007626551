<div class="result-data mat-elevation-z8 " [class.active]="searchResults.length>0" (click)="$event.stopPropagation()" >

    <!-- <div *ngFor="let result of searchResults">
        {{result.name}}
    </div> -->


    <div class="table-container mat-card">
        <table *ngIf="searchResults.length>0" mat-table multiTemplateDataRows [dataSource]="searchResults" class="result-table table-responsive" >
        
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef > Name </th>
                <td mat-cell *matCellDef="let row" data-column-name="name"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef > Type </th>
                <td mat-cell *matCellDef="let row" data-column-name="type"> {{row.parsedType}} </td>
            </ng-container>


            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="2">
                  <div class="example-element-detail"
                       [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
                            

                            <div class="result-square" [ngClass]="{'disable-row-style': row.disable }">
                                <span><b>CREATOR:</b> {{row.creator}}</span>
                                <span><b>COMMENT:</b> {{row.comment}}</span>

                                <span>
                                    <span *ngIf="row.parsedType=='record:a'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-1" selected>
                                            <b class="keep-all">Ip V4:</b> {{activeRecord?.ipv4addr}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                      <span *ngIf="row.parsedType=='record:aaaa'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-2" selected>
                                            <b class="keep-all">Ip V6:</b> {{activeRecord?.ipv6addr}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                      <span *ngIf="row.parsedType=='record:txt'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-3" selected>
                                            <b class="keep-all">Text:</b> {{activeRecord?.text}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                      <span *ngIf="row.parsedType=='record:cname'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-4" selected>
                                            <b class="keep-all">Canonical:</b> {{activeRecord?.canonical}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                      <span *ngIf="row.parsedType=='record:mx'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-5" selected>
                                            <b class="keep-all">Mail Exchanger:</b> {{activeRecord?.mail_exchanger}}
                                          </mat-chip>
                                          <mat-chip color="spectrum-6" selected>
                                            <b class="keep-all">Preference:</b> {{activeRecord?.preference}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                      <span *ngIf="row.parsedType=='record:srv'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-7" selected>
                                            <b class="keep-all">Port:</b> {{activeRecord?.port}}
                                          </mat-chip>
                                          <mat-chip color="spectrum-8" selected>
                                            <b class="keep-all">Priority:</b> {{activeRecord?.priority}}
                                          </mat-chip>
                                          <mat-chip color="spectrum-9" selected>
                                            <b class="keep-all">Target:</b> {{activeRecord?.target}}
                                          </mat-chip>
                                          <mat-chip color="spectrum-10" selected>
                                            <b class="keep-all">Weight:</b> {{activeRecord?.weight}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                      <span *ngIf="row.parsedType=='UNSUPPORTED'">
                                        <mat-chip-list class="">
                                          <mat-chip color="spectrum-10" selected *ngIf="activeRecord?.nameserver">
                                            <b class="keep-all">Nameserver:</b> {{activeRecord?.nameserver}}
                                          </mat-chip>
                                        </mat-chip-list>
                                      </span>
                                </span>

                                <span><b>ACTIONS:</b>
                                    <span *ngIf="row.name!=null && row.creator!='SYSTEM'" class="icons-list">

                                        <button mat-icon-button (click)="editRecord(row)" color="primary" aria-label="Edit subdomain"
                                          matTooltip="Edit subdomain">
                                          <mat-icon>edit</mat-icon>
                                        </button>
                                        <button mat-icon-button (click)="disableRecord(row)" color="accent" aria-label="Subdomain disable icon"
                                          matTooltip="Disable subdomains" *ngIf="!row.disable">
                                          <mat-icon>domain_disabled</mat-icon>
                                        </button>
                                        <button mat-icon-button (click)="enableRecord(row)" aria-label="Subdomain enable icon"
                                          matTooltip="Enable subdomain" *ngIf="row.disable">
                                          <mat-icon>domain</mat-icon>
                                        </button>
                                        <button mat-icon-button (click)="deleteRecord(row)" color="warn" aria-label="Delete subdomain"
                                          matTooltip="Delete subdomain">
                                          <mat-icon>delete</mat-icon>
                                        </button>
                              
                                      </span>

                                </span>
                            </div>                   

                  </div>
                </td>
              </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === row"
            [ngClass]="{'disable-row-style': row.disable }"
            (click)=" getRecordData(row._ref); expandedElement = expandedElement === row ? null : row">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
                [ngClass]="{'hide-row-style': expandedElement !== row}"
            ></tr>

            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->

            </table>
    </div>


</div>
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { MessageSnackBarService } from '../services/snack-bar.service';

/**
 * Interceptor for http calls
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    /**
     * Interceptor constructor
     * @ignore
     * @param commonService
     * @param router
     * @param snackBarService
     */
    constructor(public router: Router,
        private messageSnackBarService: MessageSnackBarService,
    ) {
    }

    /**
     * Send token, and do logout if unautorized and create messages with errors
     * @param request
     * @param next
     * @returns {any}
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        AuthInterceptor.setLimitHour()

        //if (!request || !request.url || (/^http/.test(request.url) )) {
        //return next.handle(request);
        //}

        /*const role = localStorage.getItem("roles");*/

        const user = localStorage.getItem("userName");
        const pass = sessionStorage.getItem("userPassword");

        if (!!user && !!pass) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Basic ' + btoa(user + ":" + pass)
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log(error)
                if (error.status > 300) {
                    this.messageSnackBarService.createDirectMessage(error.error.detail, 5000);
                }
                if (error.status === 401) {
                    this.logout();
                }
                return throwError(error);
            }),
            tap((event: HttpEvent<any>) => {

            }, (err: any) => console.error(err),
                () => {
                })
        );
    }

    /**
     * Logout function
     */
    logout() {
        sessionStorage.removeItem('userPassword');
        sessionStorage.removeItem('userType');

        localStorage.removeItem('userName');
                
        this.router.navigate(['/login']);
    }

    static setLimitHour() {
        var hours = 0.25;

        var now = new Date().getTime();
        var setupTime = parseInt(localStorage.getItem('setupTime') || '0');

            if (now - setupTime > hours * 60 * 60 * 1000) {
                sessionStorage.clear()
                console.log("Caducada sesión")
                localStorage.setItem('setupTime', now.toString());
            }
            else{
                localStorage.setItem('setupTime', now.toString());
            }

        /*if (setupTime == null) {
            localStorage.setItem('setupTime', now.toString())
        } else {
            if (now - setupTime > hours * 60 * 60 * 1000) {
                sessionStorage.clear()
                console.log("Caducada sesión")
                localStorage.setItem('setupTime', now.toString());
            }
        }*/
    }
}

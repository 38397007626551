<h1 mat-dialog-title>Change {{data[0].name}} NS</h1>
<div mat-dialog-content>

    <form class="example-form" [formGroup]="changeNsForm">
        <p class="aligned">
            Grid Primary <mat-slide-toggle class="slider-button" [(ngModel)]="isExternal" formControlName="isExternal"  (toggleChange)="changePrimary()"></mat-slide-toggle>  External Primary 
        </p>

        <p class="responsive-column" *ngIf="!isExternal" >
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Grid Primary</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
        </p>

        <p class="responsive-column" *ngIf="isExternal" >
            <mat-form-field appearance="fill" class="width-50">
                <mat-label>External Primary 1</mat-label>
                <input matInput formControlName="externalName">
            </mat-form-field>

            <mat-form-field appearance="fill" class="width-50">
                <mat-label>External Address 1</mat-label>
                <input matInput formControlName="externalAddress">
            </mat-form-field>
        </p>

        <p class="responsive-column" *ngIf="isExternal" >
            <mat-form-field appearance="fill"  class="width-50">
                <mat-label>External Primary 2</mat-label>
                <input matInput formControlName="externalName2">
            </mat-form-field>

            <mat-form-field appearance="fill" class="width-50">
                <mat-label>External Address 2</mat-label>
                <input matInput formControlName="externalAddress2">
            </mat-form-field>
        </p>


        <p class="responsive-column" >
            <mat-form-field appearance="fill" class="width-50">
                <mat-label>Grid Secondary 1</mat-label>
                <input matInput formControlName="secondaryName">
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-50">
                <mat-label>Grid Secondary 2</mat-label>
                <input matInput formControlName="secondaryName2">
            </mat-form-field>
        </p>





        <p class="responsive-column" >
            <mat-form-field appearance="fill" class="width-25">
                <mat-label>External Secondary 1</mat-label>
                <input matInput formControlName="externalSecondaryName1">
            </mat-form-field>

            <mat-form-field appearance="fill" class="width-25">
                <mat-label>External Address 1</mat-label>
                <input matInput formControlName="externalSecondaryAddress1">
            </mat-form-field>

            <mat-form-field appearance="fill" class="width-25">
                <mat-label>External Secondary 2</mat-label>
                <input matInput formControlName="externalSecondaryName2">
            </mat-form-field>

            <mat-form-field appearance="fill" class="width-25">
                <mat-label>External Address 1</mat-label>
                <input matInput formControlName="externalSecondaryAddress2">
            </mat-form-field>
        </p>
    </form>
</div>
<div class="dialog-options" mat-dialog-actions>
    <button mat-button (click)="closeDialog()" cdkFocusInitial>No</button>
    <button mat-raised-button  color="accent" (click)="confirmChange()" >Yes</button>
</div>
<div class="">
  <mat-toolbar color="primary" [class.hidden]="toggleSearch">
    <button
      *ngIf="isLogged && menuList.length>1"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <a class="logo-link" routerLink="/domains">
      <img [src]="logoRoute" id="logo" alt="logo-image" aria-label="logo-image">
    </a>
    <span *ngIf="(isHandset$ | async) ? false: true">{{title}}</span>

    <span *ngIf="(isHandset$ | async) || !isLogged ? false: true" class="example-spacer"></span>

    <div *ngIf="(isHandset$ | async) || !isLogged? false: true">
      <button class="navigation-button" mat-icon-button aria-label="icon for searching"  (click)="$event.stopPropagation();openSearch()">
        <mat-icon aria-label="search icon">search</mat-icon>
      </button>

    </div>
    <div *ngIf="(isHandset$ | async) || !isLogged? false: true">
      <button class="navigation-button" mat-icon-button aria-label="icon for notifications"
      [matMenuTriggerFor]="notificationMenu" >
        <mat-icon aria-label="notification icon" [matBadge]="badgeNumber" [matBadgeHidden]="badgeNumber==0" matBadgeColor="warn">notifications</mat-icon>
      </button>

    </div>

    <div *ngIf="(isHandset$ | async) ? false: true">
      <div *ngIf="isLogged" class="user-whoami" matRipple [matMenuTriggerFor]="menu">
        <button mat-mini-fab color="accent" class="circle-font" aria-label="user icon">
          {{userCap}}
        </button>
        <div class="logged-user">
          <span class="capsize">{{userName}}</span>
          <span class="subtext">Demo user for testing</span>
        </div>

      </div>
    </div>

    <div *ngIf="(isHandset$ | async) ? true: false">
      <div *ngIf="isLogged" class="user-whoami" matRipple [matMenuTriggerFor]="menu" #matMenuTrigger>
        <button mat-mini-fab color="accent" class="circle-font" aria-label="user icon">
          {{userCap}}
        </button>
      </div>
    </div>

    <mat-menu #menu="matMenu">

      <div mat-menu-item *ngIf="(isHandset$ | async) ? true: false" class="user-whoami" (click) = "$event.stopPropagation()">
        <button mat-mini-fab color="accent" class="circle-font" aria-label="user icon">
          {{userCap}}
        </button>
        <div class="logged-user">
          <span class="capsize">{{userName}}</span>
          <span class="subtext">Demo user for testing</span>
        </div>
    
      </div>

      <mat-divider *ngIf="(isHandset$ | async) ? true: false"></mat-divider>

      <button class="menu-whoami" aria-label="icon for search" mat-menu-item 
      
       *ngIf="(isHandset$ | async) ? true: false" (click)="$event.stopPropagation();openSearch()">
        Search
        <mat-icon aria-label="search icon">search</mat-icon>
      </button>


      <button class="menu-whoami" aria-label="icon for notifications" mat-menu-item  *ngIf="(isHandset$ | async) ? true: false"
      [matMenuTriggerFor]="notificationMenu">
        Notifications
        <mat-icon aria-label="notifications icon"  [matBadge]="badgeNumber" [matBadgeHidden]="badgeNumber==0" matBadgeColor="warn">notifications</mat-icon>
      </button>

      <mat-menu class="mat-menu-notification" #notificationMenu="matMenu">

        <div class="notification-menu">
          <p class="title-notification contrast-primary">Notifications</p>

          <mat-divider></mat-divider>
      
          <app-notifications>No notifications yet</app-notifications>
      
        </div>

      </mat-menu>


      <span (click)="$event.stopPropagation();changeColor()" mat-menu-item class="menu-whoami"> Dark mode
        <mat-slide-toggle class="bulb-symbol" (click)="$event.stopPropagation()" (toggleChange)="changeColor()" [(ngModel)]="lightTheme" >
          <mat-icon *ngIf="!lightTheme" class="material-symbols-outlined bulb-symbol">lightbulb</mat-icon>
          <mat-icon *ngIf="lightTheme">tips_and_updates</mat-icon>
        </mat-slide-toggle>
      </span>

      <button mat-menu-item class="menu-whoami"
        *ngIf="isLogged"
        aria-label="logout"
        (click)="logout()">
        Log Out
        <mat-icon aria-label="logout icon">logout</mat-icon>
      </button>
    </mat-menu>
  </mat-toolbar>

  <app-search-bar [(toggleSearch)]="toggleSearch"></app-search-bar>

</div>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="((isHandset$ | async) || !isLogged ) || menuList.length==1 ? 'false' : 'true'">
      <mat-nav-list>
        <a mat-list-item *ngFor="let menu of menuList" routerLink="/{{menu.link}}">
          <mat-icon class="mat-icon-rtl-mirror">
            {{menu.icon}}
          </mat-icon>
          <span *ngIf="!menu.adminNeeded || hasAdminPermissions()" class="menu-item">{{menu.name}}</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>

      <router-outlet (activate)="loggingRenew()"></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>
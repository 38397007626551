import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class IpamConsultationService {

  private IpamUrlBase = AppSettings.API_ENDPOINT;


  constructor(private http: HttpClient) { }


  getIpInfo(ip:string){
    const url = this.IpamUrlBase + "get-info" + "?ip=" + ip;

    console.log(url)

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'text/csv'
        }
      ),
    };

    return this.http.get(
      url,
      httpOptions
    );
}
}

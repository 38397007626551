import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { IpamConsultationService } from 'src/app/Main/services/ipam-consultation.service';

@Component({
  selector: 'app-infoip',
  templateUrl: './infoip.component.html',
  styleUrls: ['./infoip.component.css']
})
export class InfoipComponent implements OnInit {

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 5,
          height: "300px",
          side: { cols: 5, rows: 2 },
          tabla: { cols: 5, rows: 2 },
          miniCard: { cols: 5, rows: 1 },
          form: { cols: 5, rows: 3 }
        };
      }
 
     return {
        columns: 5,
        height: "400px",
        side: { cols: 5, rows: 2 },
        tabla: { cols: 3, rows: 2 },
        miniCard: { cols: 1, rows: 1 },
        form: { cols: 1, rows: 2 }
      };
    })
  );

  ipForm: FormGroup;

  ipv4Pattern='^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'

  ipData;

  constructor(private breakpointObserver: BreakpointObserver,
    private formBuilder: FormBuilder,
    private ipamService: IpamConsultationService) { 

      this.ipForm=this.formBuilder.group({
        ipData: ["", [Validators.required, Validators.pattern(this.ipv4Pattern)]]
      })



    }

  ngOnInit(): void {
  }


  searchIp(){

    let ip=this.ipForm.controls['ipData'].value

    this.ipamService.getIpInfo(ip).subscribe(
      success=>{
        let result=success[0]

        this.ipData = [];

        for(var i in result)
          this.ipData.push([i, JSON.stringify(result[i])]);
        
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/Main/services/configuration.service';
import { GarbageService } from 'src/app/Main/services/garbage.service';
import { MessageSnackBarService } from 'src/app/Main/services/snack-bar.service';


@Component({
  selector: 'app-garbage',
  templateUrl: './garbage.component.html',
  styleUrls: ['./garbage.component.css']
})
export class GarbageComponent implements OnInit {

  cleaningDay;
  picker;
  selectedTime;
  week: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  constructor(
    private garbageService: GarbageService,
    private configurationService: ConfigurationService,
    private messageSnackBarService: MessageSnackBarService) { }

  ngOnInit(): void {
    this.configurationService.getConfigData().subscribe(
      (success: any)=>{
        // console.log(success['cleanSchedule'])
        let schedule=success['cleanSchedule'].split(" ");
        this.selectedTime=schedule[0]+":"+schedule[1];
        this.cleaningDay=this.week[(schedule[4]-1)%7]
      
      }
    )
  }


  cleanGarbage(event){
    // console.log(event)
    this.garbageService.cleanGarbage().subscribe(
      response => {
        console.log(response);
        this.messageSnackBarService.createDirectMessage("Cleaning done", 5000);

      },
      error => {
        console.log(error)
      }
    )

  }

  saveConfig(event){
    let time=this.selectedTime.split(":");
    let weekNumber= (this.week.indexOf(this.cleaningDay)+1)%7;

    // console.log(`${time[0]} ${time[1]} * * ${weekNumber}`)

    let data={};
    data['cleanSchedule']=`${time[0]} ${time[1]} * * ${weekNumber}`;


    this.configurationService.editChanges(data).subscribe(
      success=>{

        this.garbageService.createSchedule(data['cleanSchedule']).subscribe(
          success=>
            this.messageSnackBarService.createDirectMessage("Saved configuration", 5000)
        )
      }
    );
  }
}
